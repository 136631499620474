// eslint-disable-next-line no-redeclare
import { useContext } from 'react';
import { Group, ThemeIcon, Tooltip } from '@mantine/core';
import { IconCloudDownload, IconFileExport } from '@tabler/icons';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import { openCreateContactModal } from '../../Organismns/Contact/EditContactModal';
import { ContactsContext } from '../../Service/Context/ContactsContext';
import { openExportContactsModal } from './ContactGroupsModal';

const ContactsHeadActions = () => {
  const { reload, count, filter } = useContext(ContactsContext);
  return (
    <Group spacing="xs">
      <Tooltip label="Export als CSV" transition="slide-down">
        <ThemeIcon
          size={20}
          style={{ cursor: 'pointer' }}
          variant="gradient"
          gradient={{ from: '#647c91', to: '#a2a7b0', deg: 135 }}
          sx={{ marginRight: 3 }}
        >
          <IconCloudDownload color="white" onClick={() => openExportContactsModal({ filter, count })} />
        </ThemeIcon>
      </Tooltip>
      <BookitupAddIcon onClick={() => openCreateContactModal(reload)} />
    </Group>
  );
};

export default ContactsHeadActions;

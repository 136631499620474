const Modals = {
  ContactPerson: 'contact-person-modal',
  SelectCreateContact: 'select-create-contact-modal',
};

const TrackingEvents = {
  ViewContent: 'ViewContent',
  RegistrationCompleted: 'RegistrationCompleted',
  AccountActivated: 'AccountActivated',
  AccountPasswordMissing: 'AccountPasswordMissing',
  VerificationMailExpired: 'VerificationMailExpired',
  AccountActivationRedirectFailed: 'AccountActivationRedirectFailed',
  ResendVerificationMail: 'ResendVerificationMail',
  OnboardingCompleted: 'OnboardingCompleted',
  Lead: 'Lead',
  InitiateCheckout: 'InitiateCheckout',
  Purchase: 'Purchase',
  RegisterPageOpened: 'RegisterPageOpened',
  LoginSuccessful: 'LoginSuccessful',
  PasswordForgotten: 'PasswordForgotten',
  DocumentCreated: 'DocumentCreated',
  PortalCreated: 'PortalCreated',
  EmailSent: 'EmailSent',
  QuestionnaireCreated: 'QuestionnaireCreated',
  ContractCreated: 'ContractCreated',
  LogoUploaded: 'LogoUploaded',
  MailAccountConnected: 'MailAccountConnected',
  ContactFormCreated: 'ContactFormCreated',
  ExternalCalendarAdded: 'ExternalCalendarAdded',
  CustomAttibuteCreated: 'CustomAttibuteCreated',
  DocumentTemplateOpened: 'DocumentTemplateOpened',
  DocumentTemplateCreated: 'DocumentTemplateCreated',
  ContractTemplateOpened: 'ContractTemplateOpened',
  ContractTemplateCreated: 'ContractTemplateCreated',
  QuestionnaireTemplateOpened: 'QuestionnaireTemplateOpened',
  QuestionnaireTemplateCreated: 'QuestionnaireTemplateCreated',
  EmailTemplateCreated: 'EmailTemplateCreated',
  EmailTemplateOpened: 'EmailTemplateOpened',
  RecommendationsAdded: 'RecommendationsAdded',
  RecommendationAvailabilityRequested: 'RecommendationAvailabilityRequested',
  RecommendationDataShared: 'RecommendationDataShared',
  RecommendationStateChanged: 'RecommendationStateChanged',
  RecommendationEmailSent: 'RecommendationEmailSent',
  EventCreated: 'EventCreated',
  FirstEventCreated: 'FirstEventCreated',
  EventKindCreated: 'EventKindCreated',
  EventKindDeleted: 'EventKindDeleted',
  AccountDeleted: 'AccountDeleted',
  Logout: 'Logout',
  AnswerConnectedWithAttribute: 'AnswerConnectedWithAttribute',
  AnswersTransmittedToAttributes: 'AnswersTransmittedToAttributes',
  TaskCompleted: 'TaskCompleted',
  TaskCreated: 'TaskCreated',
  AgencyModeActivated: 'AgencyModeActivated',
  GoogleAccountAdded: 'GoogleAccountAdded',
  GoogleTaskSyncActivated: 'GoogleTaskSyncActivated',
  GoogleContactsSyncActivated: 'GoogleContactsSyncActivated',
  GoogleCalendarActivated: 'GoogleCalendarActivated',
  LexOfficeEntitiesImported: 'LexOfficeEntitiesImported',
  WorkflowCreated: 'WorkflowCreated',
  TrialLicenceRedeemed: 'TrialLicenceRedeemed',
  EventsFilteredByCreationDate: 'EventsFilteredByCreationDate',
  ResourceCreated: 'ResourceCreated',
  ResourceArchived: 'ResourceArchived',
  ResourceDeleted: 'ResourceDeleted',
  PlanningGroupCreated: 'PlanningGroupCreated',
  ResourceAssignedToProduct: 'ResourceAssignedToProduct',
  ResourceAssignedToTemplatePosition: 'ResourceAssignedToTemplatePosition',
  AddonResourcePlanningTrialActivated: 'AddonResourcePlanningTrialActivated',
  AddonMissingModalClicked: 'AddonMissingPurchaseClicked',
  ResourceCategoryCreated: 'ResourceCategoryCreated',
  ResourcePhotoUploaded: 'ResourcePhotoUploaded',
  SkipOnboardingIntention: 'SkipOnboardingIntention',
  SkipOnboarding: 'SkipOnboarding',
  OnboardingConfirmed: 'OnboardingConfirmed',
  OpenOnboardingModal: 'OpenOnboardingModal',
  CloseOnboardingModal: 'CloseOnboardingModal',
  OpenVideoTutorial: 'OpenVideoTutorial',
  EventStateCreated: 'EventStateCreated',
  EventStateDeleted: 'EventStateDeleted',
  RequestedContractTemplateCreationWithOpenAI: 'RequestedContractTemplateCreationWithOpenAI',
  ContractTemplateCreatedWithOpenAI: 'ContractTemplateCreatedWithOpenAI',
  AcademyOpened: 'AcademyOpened',
  AcademyVideoSeeked: 'AcademyVideoSeeked',
  AcademyVideoOpened: 'AcademyVideoOpened',
  AcademyVideoStarted: 'AcademyVideoStarted',
  AcademyVideoProgress: 'AcademyVideoWatched',
  AcademyVideoPaused: 'AcademyVideoPaused',
  AcademyVideoEnded: 'AcademyVideoEnded',
  AzureAccountConnected: 'AzureAccountConnected',
  SevdeskAccountConnected: 'SevdeskAccountConnected',
  SevdeskInvoiceSynced: 'SevdeskInvoiceSynced',
  RegularEventDateCreated: 'RegularEventDateCreated',
  AppointmentEventDateCreated: 'AppointmentEventDateCreated',
  DownloadGdprContract: 'DownloadGdprContract',
  EventDeleted: 'EventDeleted',
  MailgunDomainVerified: 'MailgunDomainVerified',
};

const Pages = {
  Events: {
    AddEvent: 'AddEventIcon',
  },
};

// eslint-disable-next-line import/prefer-default-export
export const ConstantS = {
  Modals,
  TrackingEvents,
  Pages,
};

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Group, Loader, Text, TextInput } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { closeAllModals, openModal } from '@mantine/modals';
import { i18n } from '@lingui/core';
import { IconTemplate } from '@tabler/icons';
import BookitupDocument from '../../Types/BookitupDocument';
import { DocumentS } from '../../Templates/Documents/DocumentS';
import { isDefined, ValidationS } from '../../Service/ValidationS';

interface CreateTemplateModalProps {
  bookitupDocument: BookitupDocument;
  existingNames?: string[];
}

// eslint-disable-next-line import/prefer-default-export
export const openCreateTemplateModal = (props: CreateTemplateModalProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        {i18n._('document.template.save')}
      </Text>
    ),
    children: <CreateTemplateModal {...props} />,
  });

const CreateTemplateModal: FC<CreateTemplateModalProps> = ({ bookitupDocument, existingNames }) => {
  const { id: documentId, documentType } = bookitupDocument;
  const [takenNames, setTakenNames] = useState<string[] | undefined>(existingNames);
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const validName = ValidationS.validateTemplateName(name);
  const nameTaken = takenNames && takenNames.includes(name);

  useEffect(() => {
    if (!takenNames) {
      setLoading(true);
      DocumentS.loadTemplates(documentType)
        .then(setTakenNames)
        .finally(() => setLoading(false));
    }
  }, [documentType, takenNames]);

  const onConfirm = async () => {
    setLoading(true);
    const resJSON = await DocumentS.saveAsTemplate(name, documentId);
    setLoading(false);
    if (resJSON) {
      closeAllModals();
    }
  };

  return (
    <>
      <TextInput
        label={i18n._('template.name')}
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
        icon={<IconTemplate size={16} />}
        error={
          // eslint-disable-next-line no-nested-ternary
          !validName
            ? 'Der Name enthält unerlaubte Sonderzeichen: %/?#:&+"'
            : nameTaken
            ? 'Dieser Name ist bereits vergeben.'
            : null
        }
      />
      <Group position="right" spacing="xs" mt="xl">
        <Button variant="default" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          rightIcon={loading && <Loader size="xs" />}
          disabled={loading || !isDefined(name) || !validName || nameTaken}
          onClick={onConfirm}
        >
          {i18n._('common.apply')}
        </Button>
      </Group>
    </>
  );
};

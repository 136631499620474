import { ajaxActions } from '../../Service/AjaxActions';
import { DataS } from '../../Service/DataS';
import { fetchResourceByEntityTypeAndEntityId } from '../../Service/restapi/shareService';
import { ToastS } from '../../Service/ToastS';
import BookitupDocument, { BookitupContract, BookitupDocumentType, DocumentFilter } from '../../Types/BookitupDocument';
import { PageableResponse } from '../../Types/PageableResponse';
import { ResourcePosition } from '../../Organismns/Settings/Documents/Positions/ResourcePositionAssignment';
import { fetchContractById } from '../../Service/restapi/contractService';
import { QuestionnaireS } from '../../Service/QuestionnaireS';
import { DocumentBlueprintDto, DocumentRequestDTO, DocumentTemplateDTO } from '../../Types/MessageT';
import { ContractDto, CreateContractRequest } from '../../Types/DocumentT';

type DiscountType = 'ABSOLUTE' | 'PERCENT';

export type TaxType = 'gross' | 'net';

export type Discount = {
  value: number;
  type: DiscountType;
  description: string;
  amount: number;
};

const EMPTY_DOCUMENT = {
  id: null,
  draft: true,
  bookingState: 'OPEN',
  summable: true,
  customer: {},
  subject: 'OFFER',
  documentType: 'OFFER',
  number: '',
  daysTilExpire: '',
  headrow: '',
  positions: [{ value: '', label: '', subtext: '', price: 0, favorite: false, id: null }],
  textBlock: '',
  textTemplateDescription: '',
  endrow: '',
};

const initDocument = (contactId: number, offerValidUntil: Date | null) => {
  const date = new Date();
  return {
    ...EMPTY_DOCUMENT,
    date,
    contactId,
    validUntil: offerValidUntil || date,
  };
};

const BASE_URL = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

const fetchTemplate = async (type: BookitupDocumentType, id: number) => {
  const resp = await ajaxActions.get(`${BASE_URL}/report/template/${type.toUpperCase()}/${id}`);
  if (resp.ok) {
    return resp.json();
  }
  ToastS.generalError();
  return null;
};

const loadTemplates = (documentType: BookitupDocumentType): Promise<string[]> =>
  ajaxActions.get(`${BASE_URL}/report/template/${documentType}/names`).then((resp) => {
    if (resp.ok) {
      return resp.json();
    }
    // eslint-disable-next-line no-console
    console.error(`Unable to load templates per documentType: ${documentType}`);
    return [];
  });

type PartialInvoiceDTO = {
  amount: number;
  amountUnit: string;
};

const createPartialInvoice = async (
  origin: number,
  customerId: number,
  dto: PartialInvoiceDTO,
  templateName?: string,
) => {
  let targetUrl = `${BASE_URL}/documents/${origin}/partial-invoice?customerId=${customerId}`;
  if (templateName) targetUrl += `&templateName=${templateName}`;
  const resp = await ajaxActions.put(targetUrl, dto);
  if (resp.status === 201) {
    return resp.json();
  }
  return null;
};

const getUnassignedPartialInvoices = async (eventId: number) => {
  const resp = await ajaxActions.get(`${BASE_URL}/documents/partial-invoice/unassigned?eventId=${eventId}`);
  if (resp.ok) {
    return resp.json();
  }
  return null;
};

const patchDocument = async (
  documentId: number,
  patch: Partial<BookitupDocument>,
  interceptViolation?: (httpResponse: Response) => void,
) => {
  const resp = await ajaxActions.patch(`${BASE_URL}/documents/${documentId}`, patch);
  if (resp.ok) {
    return resp.json();
  }
  if (interceptViolation) {
    interceptViolation(resp);
  }
  return null;
};

const patchContract = async (contractId: number, patch: Partial<BookitupContract>) => {
  const resp = await ajaxActions.patch(`${BASE_URL}/contracts/${contractId}`, patch);
  if (resp.ok) {
    return resp.json();
  }
  return null;
};

const fetchById = async (id: number): Promise<BookitupDocument | null> => {
  const resp = await ajaxActions.get(`${BASE_URL}/documents/${id}`);
  if (resp.ok) {
    return resp.json();
  }
  return null;
};

const fetchResourcePositions = async (resourceId: string): Promise<ResourcePosition[]> => {
  const resp = await ajaxActions.get(`${BASE_URL}/resourcePositions?resourceId=${resourceId}`);
  if (resp.ok) {
    return resp.json();
  }
  ToastS.generalError();
  return Promise.reject();
};

const fetchResourcePositionFavorites = async (resourceId: string): Promise<ResourcePosition[]> => {
  const resp = await ajaxActions.get(`${BASE_URL}/resourcePositionFavorites?resourceId=${resourceId}`);
  if (resp.ok) {
    return resp.json();
  }
  ToastS.generalError();
  return Promise.reject();
};

const isPublished = async (entityId: number, entityType: string): Promise<boolean> => {
  const resp = await fetchResourceByEntityTypeAndEntityId(entityType, entityId);
  if (resp.ok) {
    const data: [] = await resp.json();
    return data.length > 0;
  }
  return false;
};

// eslint-disable-next-line consistent-return
const deleteDocument = async (bookitupDocument: BookitupDocument): Promise<boolean | undefined> => {
  const { id } = bookitupDocument;
  const isDocumentPublished = await isPublished(id, 'documents');

  if (isDocumentPublished) {
    ToastS.error('delete-document', 'Dokument kann nicht gelöscht werden, da es im Online-Portal freigegeben wurde.');
  } else if (cannotBeDeleted(bookitupDocument)) {
    const { paid, hasPayments } = bookitupDocument;
    // eslint-disable-next-line no-nested-ternary
    const toastMessage = hasPayments
      ? paid
        ? 'Dokument wurde festgeschrieben und kann nicht gelöscht werden'
        : 'Dokument kann nicht gelöscht werden, da bereits Zahlungen erfasst wurden'
      : 'Dokument kann nicht gelöscht werden, da es bereits fertiggestellt wurde';
    ToastS.error('delete-document', toastMessage);
  } else {
    const resp = await ajaxActions.del(`${BASE_URL}/documents/${id}`);
    if (resp.ok) {
      ToastS.info('delete.document', 'Dokument wurde gelöscht');
      return true;
    }
    ToastS.generalError();
    return false;
  }
};

const deleteContract = async (id: number): Promise<boolean> => {
  const isContractPublished = await isPublished(id, 'contracts');
  if (isContractPublished) {
    ToastS.error('delete-document', 'Dokument kann nicht gelöscht werden, da es im Online-Portal freigegeben wurde.');
    return false;
  }
  const resp = await ajaxActions.del(`${BASE_URL}/contracts/${id}`);
  if (resp.ok) {
    ToastS.info('delete.document', 'Dokument wurde gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const cannotBeDeleted = (bookitupDocument: BookitupDocument): boolean => {
  const { documentType, draft } = bookitupDocument;
  return !draft && (documentType === 'CANCEL' || isInvoice(documentType));
};

const isInvoice = (documentType: BookitupDocumentType): boolean =>
  documentType === 'INVOICE' || documentType === 'PARTIAL_INVOICE';

const fetchDocuments = async (
  filter: DocumentFilter,
  setEmptyDocuments: (val: boolean) => void,
  abortSignal: AbortSignal,
): Promise<PageableResponse<BookitupDocument> | null> => {
  const params = DataS.removeEmptyValues(filter);
  const query = new URLSearchParams(params as any).toString();
  const resp = await ajaxActions.get(`${BASE_URL}/documents/filter?${query}`, { signal: abortSignal });
  if (resp.ok) {
    const totalCountHeader = resp.headers.get('X-Total-Count');
    if (totalCountHeader && totalCountHeader === '0') {
      setEmptyDocuments(true);
    } else {
      setEmptyDocuments(false);
    }
    return resp.json();
  }
  return null;
};

const fetchContracts = async (
  filter: DocumentFilter,
  setEmptyDocuments: (val: boolean) => void,
  abortSignal: AbortSignal,
): Promise<PageableResponse<BookitupContract> | null> => {
  const params = DataS.removeEmptyValues(filter);
  const query = new URLSearchParams(params as any).toString();
  const resp = await ajaxActions.get(`${BASE_URL}/contracts/filter?${query}`, { signal: abortSignal });
  if (resp.ok) {
    const totalCountHeader = resp.headers.get('X-Total-Count');
    if (totalCountHeader && totalCountHeader === '0') {
      setEmptyDocuments(true);
    } else {
      setEmptyDocuments(false);
    }
    return resp.json();
  }
  return null;
};

interface GroupedDocuments {
  [key: string]: BookitupDocument[];
}

const groupDocumentsByType = (documents: BookitupDocument[]): GroupedDocuments => {
  const groupedDocuments: GroupedDocuments = {};
  ['OFFER', 'CONFIRMATION', 'PARTIAL_INVOICE', 'INVOICE', 'CANCEL'].forEach((docType) => {
    const filteredDocs = documents.filter((d) => d.documentType === docType);
    if (filteredDocs.length > 0) {
      groupedDocuments[docType] = filteredDocs;
    }
  });
  return groupedDocuments;
};

const importProductsLOX = async (csvFile: File) => {
  ToastS.info(
    'importing-products',
    'Abhängig von der Anzahl Ihrer Produkte/Service kann es einen Moment dauern.',
    'Produkte/Service importieren',
  );
  const res = await ajaxActions.uploadFile(`${BASE_URL}/import/products/lox`, csvFile);
  if (res.ok) {
    const importedProductsCount = await res.text();
    ToastS.success(
      'products-imported',
      `${importedProductsCount} Produkte erfolgreich importiert`,
      'Produkte/Service importiert',
    );
    return;
  }
  ToastS.generalError();
};

const getEntityFileInfo = async (entityId: string, entityType: string) => {
  let fileName;
  if (entityType === 'contracts') {
    const res = await fetchContractById(entityId);
    if (res.ok) {
      const resJSON = await res.json();
      fileName = resJSON.fileName;
    }
  }
  if (entityType === 'documents') {
    const _document = await DocumentS.fetchById(Number(entityId));
    if (_document) {
      fileName = _document.fileName;
    }
  }
  if (entityType === 'questionnaires') {
    const questionnaire = await QuestionnaireS.fetchQuestionnaire(entityId);
    if (questionnaire) {
      fileName = questionnaire.fileName;
    }
  }
  return {
    entityId,
    entityType,
    fileName,
  };
};

const exchangeExistingDocuments = async (
  eventId: string,
  documentRequests: DocumentBlueprintDto[],
): Promise<DocumentRequestDTO[]> => {
  const res = await ajaxActions.post(`${BASE_URL}/process/exchangeToExistingDocumentsIfPossible`, {
    eventId,
    documentRequests,
  });
  if (res.ok) {
    return res.json();
  }
  return [];
};

const stringifyBlueprints = (documentBlueprints: DocumentBlueprintDto[]) => {
  const dBArray: string[] = [];
  documentBlueprints.forEach((dB) => {
    if (dB.documentType === 'OFFER') {
      dBArray.push('ein Angebot');
    } else if (dB.documentType === 'CONFIRMATION') {
      dBArray.push('eine Auftragbestätigung');
    } else if (dB.documentType === 'PARTIAL_INVOICE') {
      dBArray.push('ein Abschlagsrechnung');
    } else if (dB.documentType === 'INVOICE') {
      dBArray.push('eine Rechnung');
    } else if (dB.documentType === 'CANCEL') {
      dBArray.push('eine Rechnungskorrektur');
    } else if (dB.documentType === 'CONTRACT') {
      dBArray.push('ein Vertrag');
    }
  });
  let output = dBArray[0];
  if (dBArray.length > 2) {
    output = dBArray.slice(0, dBArray.length - 1).join(', ');
    output += ` und ${dBArray[dBArray.length - 1]}`;
  } else if (dBArray.length > 1) {
    output = `${dBArray[0]} und ${dBArray[1]}`;
  }
  return output;
};

const getContractTemplatesNames = async (): Promise<string[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/contracts/templates/names`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const createContract = async (req: CreateContractRequest): Promise<ContractDto | null> => {
  const requestParams = new URLSearchParams(req as any).toString();
  const res = await ajaxActions.put(`${BASE_URL}/contracts?${requestParams}`);
  if (res.ok) {
    ToastS.success('contract-created', 'Vertrag erstellt');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const contractCreatable = (documentType: BookitupDocumentType) =>
  documentType === 'OFFER' || documentType === 'CONFIRMATION' || documentType === 'INVOICE';

const saveAsTemplate = async (templateName: string, documentId: number): Promise<DocumentTemplateDTO | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/templates/${templateName}?documentId=${documentId}`);
  if (res.ok) {
    ToastS.info('template-created', 'Vorlage erstellt.');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

// eslint-disable-next-line import/prefer-default-export
export const DocumentS = {
  initDocument,
  fetchTemplate,
  loadTemplates,
  createPartialInvoice,
  getUnassignedPartialInvoices,
  patchDocument,
  fetchDocuments,
  fetchContracts,
  fetchResourcePositions,
  fetchResourcePositionFavorites,
  fetchById,
  patchContract,
  deleteDocument,
  isInvoice,
  groupDocumentsByType,
  deleteContract,
  isPublished,
  importProductsLOX,
  getEntityFileInfo,
  exchangeExistingDocuments,
  stringifyBlueprints,
  getContractTemplatesNames,
  createContract,
  contractCreatable,
  saveAsTemplate,
};

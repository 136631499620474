// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Loader, Text, Tooltip } from '@mantine/core';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { openModal } from '@mantine/modals';
import PanePaper from '../../PanePaper';
import { EventContext } from '../../../Service/Context/EventContext';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { RecommendationDto } from '../../../Types/RecommendationT';
import { RecommendationS } from '../../../Service/RecommendationS';
import { EventLocationContext } from '../../../Service/Context/EventLocationContext';
import { useAppUser } from '../../../Service/Context/ApplicationUserContext';
import { EventDate } from '../../../Types/Event';
import RecommendationSource from './RecommendationSource';
import RecommendationRequests from './RecommendationRequests';
import AddRecommendationModal from './Modals/AddRecommendationModal';
import BookitupThemeIcon from '../../../Atoms/BookitupThemeIcon';
import { IconRefresh } from '@tabler/icons';

interface RecommenationsContextInterface {
  recommendations: RecommendationDto[];
  loading: boolean;
  reload: () => void;
  removeRecommendation: (recommendationId: string) => void;
  removeAll: () => void;
  patchRecommendation: (recommendation: RecommendationDto) => void;
}

export const RecommendationContext = createContext<RecommenationsContextInterface>(
  {} as RecommenationsContextInterface,
);

const RecommendationPane = () => {
  const [recommendations, setRecommendations] = useState<RecommendationDto[]>([]);
  const { redGreenWeakness } = useAppUser();
  const [loading, setLoading] = useState(false);
  const { event, dates } = useContext<any>(EventContext);
  const { locations } = useContext<any>(EventLocationContext);
  const { id: eventId, recommendationId } = event;
  const noEventsToRecommend = dates.filter((d: EventDate) => d.type === 'REGULAR_DATE').length === 0;

  const fetchRecommendations = useCallback(() => {
    setLoading(true);
    RecommendationS.fetchByEventId(eventId)
      .then(setRecommendations)
      .finally(() => {
        setLoading(false);
      });
  }, [eventId]);

  useEffect(() => {
    fetchRecommendations();
  }, [eventId, fetchRecommendations]);

  const removeRecommendation = (id: string) =>
    setRecommendations(recommendations.filter((_recommendation) => _recommendation.id !== id));

  const removeAll = () => setRecommendations([]);

  const patchRecommendation = (recommendation: RecommendationDto) => {
    const { id } = recommendation;
    setRecommendations(
      recommendations.map((_recommendation) => (_recommendation.id === id ? recommendation : _recommendation)),
    );
  };

  const appendRecommendations = (_recommendations: RecommendationDto[]) => {
    setRecommendations([...recommendations, ..._recommendations]);
  };

  const value = useMemo(
    () => ({
      recommendations,
      loading,
      reload: fetchRecommendations,
      removeRecommendation,
      removeAll,
      patchRecommendation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recommendations, loading],
  );

  const openAddRecommendationModal = () =>
    openModal({
      size: 'md',
      title: (
        <Text weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Dienstleister auswählen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: (
        <AddRecommendationModal
          event={event}
          dates={dates.filter((d: EventDate) => d.type === 'REGULAR_DATE')}
          appendRecommendations={appendRecommendations}
          redGreenWeakness={redGreenWeakness}
          availableLocations={locations}
        />
      ),
    });

  if (dates.length === 0) {
    return null;
  }

  return (
    <PanePaper
      withShadow
      withoutBorder
      title="Vermittlungen"
      tooltip="help.event.recommendations.pane"
      rightSection={
        <Group spacing="xs">
          {!loading && !noEventsToRecommend && (
            <BookitupThemeIcon sx={{ marginTop: -8, cursor: 'pointer' }} onClick={fetchRecommendations}>
              <IconRefresh />
            </BookitupThemeIcon>
          )}
          {loading && <Loader size="xs" mt={-8} />}

          <Tooltip
            multiline
            label="Hinterlege erst ein Event-Datum, für das Du eine Vermittlung hinzufügen möchtest."
            transition="slide-down"
            position="bottom-end"
            width={250}
            disabled={!noEventsToRecommend}
          >
            <div>
              <BookitupAddIcon onClick={openAddRecommendationModal} disabled={noEventsToRecommend} />
            </div>
          </Tooltip>
        </Group>
      }
    >
      <RecommendationSource recommendationId={recommendationId} />
      <RecommendationContext.Provider value={value}>
        <RecommendationRequests />
      </RecommendationContext.Provider>
    </PanePaper>
  );
};

export default RecommendationPane;

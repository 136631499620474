import { useListState } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { MailOutBoundConfigDTO } from '../../Types/MailT';
import { MailS } from '../MailS';

// eslint-disable-next-line import/prefer-default-export
export const useMailOutboundConfigs = () => {
  const [mailOutboundConfigs, handlers] = useListState<MailOutBoundConfigDTO>([]);
  const [loading, setLoading] = useState(false);
  const { append, setState, applyWhere, filter } = handlers;

  useEffect(() => {
    fetchConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchConfigs = () => {
    setLoading(true);
    MailS.fetchMailOutboundConfigs()
      .then(setState)
      .finally(() => setLoading(false));
  };

  const editConfig = (config: MailOutBoundConfigDTO) => {
    applyWhere(
      (cfg) => cfg.id === config.id,
      () => config,
    );
  };

  const removeConfig = (configId: number) => filter((cfg) => cfg.id !== configId);

  return { loading, mailOutboundConfigs, append, editConfig, removeConfig, refresh: fetchConfigs };
};

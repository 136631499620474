// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Autocomplete, AutocompleteItem, AutocompleteProps, Loader, Text } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { useLingui } from '@lingui/react';
import { IconUser } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import AutoCompleteEventItem from '../../Atoms/Autocomplete/AutoCompleteEventItem';
import { getDescription } from '../../Service/restapi/eventService';
import BookingEvent, { BookingEventDateItem } from '../../Types/Event';
import { EventS } from '../../Service/EventS';
import { isDefined } from '../../Service/ValidationS';
import { EventsContext } from '../../Service/Context/EventsContext';

type Props = Partial<AutocompleteProps>;

const EventSearchAutocomplete: React.FC<Props> = ({ ...rest }) => {
  const { i18n } = useLingui();
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState([]);
  const { query, setQuery } = useContext(EventsContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isDefined(query)) {
      if (events.length > 0) {
        setEvents([]);
      }
      return;
    }
    setLoading(true);
    const controller = new AbortController();
    EventS.search(query, controller.signal)
      .then(enrichEvents)
      .finally(() => {
        if (!controller.signal.aborted) {
          setLoading(false);
        }
      });
    // eslint-disable-next-line consistent-return
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const onSubmit = (item: AutocompleteItem) => {
    const selectedEvent = item as unknown as BookingEvent;
    navigate(`/events/${selectedEvent.id}/details`);
  };

  const enrichEvents = (bookingEvents: BookingEventDateItem[]) => {
    const enrichedEvents: any = bookingEvents.map((bookingEvent: BookingEventDateItem) => ({
      ...bookingEvent,
      value: getDescription(bookingEvent, i18n),
    }));
    setEvents(enrichedEvents);
  };

  return (
    <Autocomplete
      variant="filled"
      maxDropdownHeight={window.innerHeight * 0.7}
      defaultValue={query ?? ''}
      rightSection={loading && <Loader size="sm" />}
      icon={<IconUser size={16} />}
      placeholder="Kunde durchsuchen"
      itemComponent={AutoCompleteEventItem}
      nothingFound={!loading && events.length === 0 && query !== '' && <Text> Keine Events </Text>}
      limit={10}
      onItemSubmit={onSubmit}
      onChange={setQuery}
      zIndex={9999}
      radius="sm"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      data={events}
    />
  );
};

export default EventSearchAutocomplete;

/* eslint-disable react/jsx-props-no-spreading */
import { Text, SelectItemProps } from '@mantine/core';
import { forwardRef } from 'react';
import Moment from 'react-moment';
import EventDate from '../../Types/EventDate';
import Contact from '../../Types/Contact';
import BookingState from '../../Types/Enums/BookingState';
import { EventS } from '../../Service/EventS';
import { CustomEventState } from '../../Types/EventT';
import BookingEvent from '../../Types/Event';

interface EventItemProps extends SelectItemProps {
  customer: Contact;
  bookingState: BookingState;
  kind?: string;
  dates: EventDate[];
  eventState: CustomEventState | null;
}

const AutoCompleteEventItem = forwardRef<HTMLDivElement, EventItemProps>(
  ({ customer, bookingState, kind, dates, eventState, ...rest }: EventItemProps, ref) => (
    <div
      ref={ref}
      style={{
        borderBottom: '1px solid #e0e0e0',
        borderLeft: `5px solid ${EventS.getStateColor({ bookingState, eventState } as BookingEvent)}`,
      }}
      {...rest}
    >
      <Text sx={{ fontSize: 8 }} color="dimmed">
        {eventState ? `${eventState.emoji} ${eventState.label}` : EventS.translateBookingState(bookingState)}
      </Text>
      <Text inline size="xs">
        {customer.personDescription || customer.email}
      </Text>
      {dates.length > 0 && (
        <Text sx={{ fontSize: 10 }} color="dimmed">
          {EventS.getKindDescription(kind)} am <Moment format="DD.MM.YYYY">{dates[0].date}</Moment>
        </Text>
      )}
    </div>
  ),
);

export default AutoCompleteEventItem;

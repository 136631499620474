import { FC, useEffect, useState } from 'react';
import { Code, Group, Skeleton, Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { ProcedureReportProps, SendMailConfigDTO } from '../../../../Types/LogT';
import { MessageS } from '../../../../Service/MessageS';
import { MessageTemplateDto } from '../../../../Types/MessageT';
import { IconPaperclip } from '@tabler/icons';

const SendMailReport: FC<ProcedureReportProps> = ({ report, config }) => {
  const { templateId, attachments } = config as SendMailConfigDTO;
  const { executionStatus } = report ?? {};
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState<MessageTemplateDto>();

  useEffect(() => {
    if (templateId) {
      setLoading(true);
      MessageS.fetchTemplate(templateId)
        .then((resJSON) => {
          if (resJSON) {
            setTemplate(resJSON);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [templateId]);

  const getLabel = () => {
    if (template) {
      const { messageType, name } = template;
      if (messageType !== 'CUSTOM') {
        return i18n._(`message.type.${messageType.toLowerCase()}`);
      }
      return name;
    }
    return null;
  };

  if (!templateId) {
    return <Text color="red">Ungültige Konfiguration</Text>;
  }

  return (
    <>
      <Text>
        Nachricht{' '}
        {loading || !template ? (
          <Skeleton mb={-3} height={16} width={100} sx={{ display: 'inline-block' }} />
        ) : (
          <Text span weight="bolder">
            {getLabel()}
          </Text>
        )}
        {'  '}
        {executionStatus === 'COMPLETED' ? 'wurde' : 'wird'} geschickt
      </Text>
      {attachments.length > 0 && (
        <Group spacing={5}>
          {attachments.map((attachment) => (
            <Code key={attachment.id}>
              <IconPaperclip size={16} /> {attachment.label}
            </Code>
          ))}
        </Group>
      )}
    </>
  );
};

export default SendMailReport;

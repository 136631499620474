// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SwitchProfile from '../../Molecules/Dialogs/SwitchProfileModal';
import { Profile, ProfileS } from '../ProfileS';
import { AuthS, getOwnProfiles, getProfileShares } from '../restapi/authService';
import { AuthContext } from './AuthContext';

interface ProfileContextInterface {
  profile?: Profile;
  handleProfileChange: (patch: Partial<Profile>) => void;
  patchProfile: (patch: Partial<Profile>) => void;
  switchProfile: () => void;
  hasSwitchableProfiles: boolean;
  loadingProfiles: boolean;
  isOwnProfile: boolean;
}

export const ProfileContext = React.createContext<ProfileContextInterface>({} as ProfileContextInterface);

export const ProfileContextProvider = () => {
  const { openModal } = useModals();
  const { jwtClaims } = useContext(AuthContext);
  const [profile, setProfile] = useState<Profile>();
  const [sharedProfiles, setSharedProfiles] = useState<Profile[]>([]);
  const [ownProfiles, setOwnProfiles] = useState<Profile[]>([]);
  const [loadingOwnProfiles, setLoadingOwnProfiles] = useState<boolean>(false);
  const [loadingSharedProfiles, setLoadingSharedProfiles] = useState<boolean>(false);
  const [isOwnProfile, setIsOwnProfile] = useState(true);
  const allProfiles = sharedProfiles.concat(ownProfiles);
  const hasSwitchableProfiles = allProfiles.length > 1;
  const { profileId } = jwtClaims;

  const checkAndSwitch = (selectedProfile: Profile) => {
    if (!profile || profile.id !== selectedProfile.id) {
      ProfileS.switchProfile(selectedProfile.id);
    }
  };

  const openSwitchProfileModal = () => {
    openModal({
      title: (
        <Text size="xl" weight="bolder">
          Profil wechseln
        </Text>
      ),
      size: 500,
      padding: 'xl',
      closeOnEscape: true,
      withCloseButton: true,
      children: (
        <SwitchProfile
          currentProfile={profile || ({} as Profile)}
          profiles={sharedProfiles.concat(ownProfiles)}
          switchProfile={checkAndSwitch}
        />
      ),
    });
  };

  const patchProfile = (patch: Partial<Profile>) => {
    if (profileId) {
      ProfileS.patchProfile(profileId, patch).then((patchedProfile) => {
        if (patchedProfile) {
          setProfile(patchedProfile);
        }
      });
    }
  };

  const handleProfileChange = (patch: Partial<Profile>) => {
    setProfile(
      (prevState) =>
        ({
          ...prevState,
          ...patch,
        } as Profile),
    );
  };

  useEffect(() => {
    if (profileId) {
      setLoadingOwnProfiles(true);
      setLoadingSharedProfiles(true);

      getOwnProfiles()
        .then((resp) => {
          if (resp.ok) {
            resp.json().then(setOwnProfiles);
          }
        })
        .finally(() => setLoadingOwnProfiles(false));

      getProfileShares()
        .then((resp) => {
          if (resp.ok) {
            resp.json().then(setSharedProfiles);
          }
        })
        .finally(() => setLoadingSharedProfiles(false));
    }
  }, [profileId]);

  useEffect(() => {
    if (profileId) {
      AuthS.fetchProfile(profileId).then((resp) => {
        if (resp.ok) {
          resp.json().then(setProfile);
        }
      });
      ProfileS.isOwnProfile(profileId).then(setIsOwnProfile);
    }
  }, [profileId]);

  const value = useMemo(
    () => ({
      profile,
      handleProfileChange,
      patchProfile,
      switchProfile: openSwitchProfileModal,
      hasSwitchableProfiles,
      isOwnProfile,
      loadingProfiles: loadingOwnProfiles || loadingSharedProfiles,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile, hasSwitchableProfiles, loadingOwnProfiles, loadingSharedProfiles, isOwnProfile],
  );

  return (
    <ProfileContext.Provider value={value}>
      <Outlet />
    </ProfileContext.Provider>
  );
};

export const useOwnProfile = () => {
  const { profile } = useContext(ProfileContext);
  return { profile };
};

import { Button, Checkbox, Group, Stack, Text } from '@mantine/core';
import PanePaper from '../../Molecules/PanePaper';
import { DatePicker } from '@mantine/dates';
import React, { useContext, useState } from 'react';
import { DateUtils } from '../../Utils/dateUtils';
import { addDays } from 'date-fns';
import { AuthS } from '../../Service/AuthS';
import FilePicker from '../../Atoms/FilePicker';
import { EventS } from '../../Service/EventS';
import { useJwtClaims } from '../../Service/Context/AuthContext';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { ContactS } from '../../Service/ContactS';
import LexOfficeImportPane from "../Settings/Accounting/lox/LexOfficeImportPane";

const ManagementPage = () => {
  const { supportLogin } = useJwtClaims();
  if (!supportLogin) {
    return null;
  }
  return (
    <Stack>
      <Group p="xl">
        <ProfileInfoPane />
        <LicenceTrialPane />
        <EventImportPage />
        <ContractImportPane />
      </Group>
      <Group p="xl">
        <PanePaper title="lexware Office" sx={{ height: 320, width: 500 }}>
          <LexOfficeImportPane />
        </PanePaper>
      </Group>
    </Stack>

  );
};

const LicenceTrialPane = () => {
  const [expiredAt, setExpiredAt] = useState<Date>(addDays(new Date(), 14));
  const period = DateUtils.getDurationInDays(new Date(), expiredAt);

  const activateTrial = () => AuthS.activateTrial(expiredAt);

  return (
    <PanePaper title="Activate trial" sx={{ height: 200 }}>
      <DatePicker
        label="Valid until"
        minDate={new Date()}
        value={expiredAt}
        onChange={(val) => val && setExpiredAt(val)}
      />
      <Button onClick={activateTrial} mt="xl">{`Activate for ${period} days`}</Button>
    </PanePaper>
  );
};

const EventImportPage = () => {
  const [importPastEvents, setImportPastEvents] = useState(true);
  const [importCancelledEvents, setImportCancelledEvents] = useState(false);

  const importEvents = (csvFile: File) => EventS.importEventsCSV(csvFile, importPastEvents, importCancelledEvents);

  return (
    <PanePaper title="Import events" sx={{ height: 200 }}>
      <Checkbox
        checked={importPastEvents}
        onChange={() => setImportPastEvents(!importPastEvents)}
        label="Import past events"
      />
      <Checkbox
        mb="xl"
        checked={importCancelledEvents}
        onChange={() => setImportCancelledEvents(!importCancelledEvents)}
        label="Import cancelled events"
      />
      <FilePicker
        id="events_import"
        onSelectFile={importEvents}
        accept=".csv"
        label={
          <Text variant="link" color="blue" sx={{ cursor: 'pointer' }}>
            Importieren
          </Text>
        }
      />
    </PanePaper>
  );
};

const ContractImportPane = () => {
  const importContacts = (csvFile: File) => ContactS.importContactsCSV(csvFile);

  return (
    <PanePaper title="Import contacts" sx={{ height: 200 }}>
      <FilePicker
        id="contacts_import"
        onSelectFile={importContacts}
        accept=".csv"
        label={
          <Text variant="link" color="blue" sx={{ cursor: 'pointer' }}>
            Importieren
          </Text>
        }
      />
    </PanePaper>
  );
};

const ProfileInfoPane = () => {
  const { profile } = useContext(ProfileContext);

  return (
    <PanePaper title="Account Infos" sx={{ height: 200 }}>
      <Stack>
        <Text>Profil-Nr.: {profile?.id}</Text>
        <Text>Profil-User: {profile?.alias}</Text>
        <Text>Services: {profile?.services}</Text>
        <Text>Licence: {profile?.licence}</Text>
      </Stack>
    </PanePaper>
  );
};

export default ManagementPage;

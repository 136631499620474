import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { useModals } from '@mantine/modals';
import { Trans } from '@lingui/react';
import { CustomModalHeader } from '../../Molecules/Dialogs';
import { Checkbox, Group, Skeleton, Text } from '@mantine/core';

const EditableTaskText = ({
  finishCallback,
  value,
  placeholder,
  disabled,
  patchField,
  style,
  task: initialTask,
  ...rest
}) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const [task, setTask] = useState(initialTask);

  const { openContextModal, closeAll } = useModals();

  useEffect(() => {
    setTask(initialTask);
  }, [initialTask]);

  if (!task) {
    return null;
  }
  const { name, dueDate, priority } = task;

  const toggleCompleted = () => {
    setLoading(true);
    finishCallback({ [patchField]: !value })
      .catch((error) => setError(error.message))
      .then(() => setLoading(false));
  };

  const openTaskEditModal = () =>
    openContextModal('taskEditModal', {
      title: (
        <CustomModalHeader>
          <Trans id="task.edit" />
        </CustomModalHeader>
      ),
      innerProps: {
        initialTask: task,
        entityType: 'events',
        finish: (updatedTask) => {
          setTask(updatedTask);
          closeAll();
        },
      },
      centered: true,
    });

  const expired = dueDate !== null && new Date(dueDate).getTime() < new Date().getTime();

  return (
    <div>
      {loading && <Skeleton height={20} circle />}
      {!loading && (
        <Checkbox
          label={
            <div>
              <Text
                className="overflow-ellipsis"
                variant="link"
                onClick={(e) => {
                  e.preventDefault(); // Don't mark task as completed
                  openTaskEditModal();
                }}
                weight={priority ? 'bolder' : 'normal'}
                td={value ? 'line-through' : 'none'}
                sx={{
                  cursor: 'pointer',
                  width: 200,
                  color: expired && !value ? 'red' : 'black',
                  overflow: 'hidden',
                }}
              >
                {name}
              </Text>
              {dueDate && (
                <Text color="dimmed" size="xs">
                  Erledigen bis{' '}
                  <Moment calendar format="DD.MM.YYYY">
                    {task.dueDate}
                  </Moment>
                </Text>
              )}
            </div>
          }
          radius="xl"
          disabled={disabled}
          checked={value}
          onChange={toggleCompleted}
        />
      )}
      {error ? (
        <Text color="red" size="xs">
          {error}
        </Text>
      ) : null}
    </div>
  );
};

EditableTaskText.propTypes = {
  value: PropTypes.bool,
  finishCallback: PropTypes.func,
};

export default EditableTaskText;

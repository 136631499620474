import { Trans } from '@lingui/react';
import { Menu } from '@mantine/core';
import { IconDotsVertical, IconTrash } from '@tabler/icons';
import { useContext, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { TransButtonDefault, TransButtonPrimaryWithSpinner } from '../../Atoms/Buttons';
import { DocumentContext } from '../../Service/Context/DocumentContext';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { LicenceS } from '../../Service/LicenceS';
import { deleteDocumentHelper } from '../../Service/restapi/documentService';
import { newDocumentOfDocumentAndTemplate } from '../../Service/restapi/templateService';
import { ConfirmDialog, ModalDialog } from '../Dialogs';
import SelectDocumentTemplate from './SelectDocumentTemplate';
import { openCreateTemplateModal } from './DocumentModals';

const DocumentActions = () => {
  const navigate: NavigateFunction = useNavigate();
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { document: bookitupDocument, handleChange } = useContext<any>(DocumentContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState(false);
  const { id: documentId, eventId, documentType } = bookitupDocument;
  const [modalOpenSelectTemplate, setModalOpenSelectTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();

  function deleteHandler() {
    deleteDocumentHelper(documentId, () => {
      if (eventId) {
        navigate(`/events/${eventId}/documents`);
      } else {
        navigate('/documents');
      }
    });
  }

  function load() {
    const fn = () => {
      if (selectedTemplate) {
        setLoading(true);
        newDocumentOfDocumentAndTemplate(documentType, selectedTemplate, documentId)
          .then((resp) => {
            if (resp.ok) {
              resp.json().then((newDocument) => {
                handleChange(newDocument);
                setModalOpenSelectTemplate(!modalOpenSelectTemplate);
                if (eventId) {
                  navigate(`/events/${eventId}/documents/${newDocument.id}`);
                } else {
                  navigate(`/documents/${newDocument.id}`);
                }
              });
            }
          })
          .finally(() => setLoading(false));
      }
    };
    if (selectedTemplate !== 'Standard') {
      executeWithLicenceCheck(LicenceS.Restrictions.MULTIPLE_DOCUMENT_TEMPLATE, fn);
    } else {
      fn();
    }
  }

  return (
    <div>
      <Menu withArrow shadow="md" width={200} transition="scale-y" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => setModalOpenSelectTemplate(true)}>Vorlage auswählen</Menu.Item>
          <Menu.Item onClick={() => openCreateTemplateModal({ bookitupDocument })}>Vorlage speichern</Menu.Item>
          <Menu.Item onClick={() => setModalDelete(!modalDelete)} icon={<IconTrash size={16} />}>
            Löschen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <ModalDialog
        header={<Trans id="template.apply" />}
        body={
          <SelectDocumentTemplate
            value={selectedTemplate}
            onChange={(option: any) => setSelectedTemplate(option.value)}
          />
        }
        footer={
          <div>
            <TransButtonDefault
              onClickHandler={() => setModalOpenSelectTemplate(!modalOpenSelectTemplate)}
              text="actions.close"
            />
            <TransButtonPrimaryWithSpinner isLoading={loading} onClickHandler={load} text="actions.apply" />{' '}
          </div>
        }
        show={modalOpenSelectTemplate}
        toggle={() => setModalOpenSelectTemplate(!modalOpenSelectTemplate)}
      />

      <ConfirmDialog
        text="common.delete.document.confirm"
        subject="common.delete.document.confirm.head"
        open={modalDelete}
        toggle={() => setModalDelete(!modalDelete)}
        handler={() => deleteHandler()}
        // eslint-disable-next-line react/no-children-prop
        children={[]}
        isLoading={false}
        confirmText=""
        disableConfirm={false}
      />
    </div>
  );
};

export default DocumentActions;

import { BookingState, RecommendationDto, RequestAnswer, AvailabilityCache } from '../Types/RecommendationT';
import { ajaxActions } from './AjaxActions';
import { ConstantS } from './ConstantS';
import { MixpanelS } from './MixpanelS';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_RECOMMENDATION_SERVICE_URL;

const create = async (eventId: number, contactIds: number[], eventDatesIds: number[]): Promise<RecommendationDto[]> => {
  const res = await ajaxActions.put(`${BASE_URL}/recommendations`, { eventId, contactIds, eventDatesIds });
  if (res.ok) {
    MixpanelS.track(ConstantS.TrackingEvents.RecommendationsAdded, { count: contactIds.length });
    ToastS.success('mediation-created', 'Vermittlungen erstellt');
    return res.json();
  }
  ToastS.generalError();
  return [];
};

const fetchById = async (recommendationId: string): Promise<RecommendationDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/recommendations/${recommendationId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchByEventId = async (eventId: number): Promise<RecommendationDto[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/recommendations/events/${eventId}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const edit = async (recommendationId: string, patch: Partial<RecommendationDto>): Promise<RecommendationDto | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/recommendations/${recommendationId}`, patch);
  if (res.ok) {
    if (patch.shareContact) {
      MixpanelS.track(ConstantS.TrackingEvents.RecommendationDataShared);
    }
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const editAndInform = async (
  recommendationId: string,
  bookingState: BookingState,
  message: string,
  serviceProvider?: boolean,
): Promise<RecommendationDto | null> => {
  const res = await ajaxActions.patch(
    `${BASE_URL}/bookingState/recommendations/${recommendationId}${serviceProvider ? '?serviceProvider=true' : ''}`,
    {
      bookingState,
      message,
    },
  );
  if (res.ok) {
    MixpanelS.track(ConstantS.TrackingEvents.RecommendationStateChanged);
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const remove = async (recommendationId: string, sendEmail: boolean, message: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/recommendations/${recommendationId}?sendMail=${sendEmail}`, {
    message,
  });
  if (res.ok) {
    ToastS.info('recommendation-removed', 'Vermittlung wurde gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const removeEventRecommendations = async (eventId: number, sendEmail: boolean, message: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/recommendations/event/${eventId}?sendMail=${sendEmail}`, {
    message,
  });
  if (res.ok) {
    ToastS.info('recommendations-removed', 'Vermittlungen wurden gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const sendInfoMail = async (recommendationId: string, message: string): Promise<boolean> => {
  const res = await ajaxActions.put(`${BASE_URL}/recommendations/${recommendationId}/sendInfoMail`, {
    message,
  });
  if (res.ok) {
    MixpanelS.track(ConstantS.TrackingEvents.RecommendationEmailSent);
    ToastS.success('send.mail.info', 'E-Mail an Dienstleister gesendet');
    return true;
  }
  ToastS.error('send.mail.info', 'E-Mail konnte nicht gesendet werden');
  return false;
};

const checkAvailability = async (recommendationId: string, message: string): Promise<RecommendationDto | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/recommendations/${recommendationId}/checkAvailability`, { message });
  if (res.ok) {
    return res.json();
  }
  if (res.status === 424) {
    ToastS.error('email.contact.missing', 'Keine E-Mail-Adresse für Dienstleister-Kontakt gespeichert');
  } else {
    ToastS.generalError();
  }
  return null;
};

const checkAvailabilities = async (recommendationsIds: string[], message: string): Promise<boolean> => {
  const res = await ajaxActions.post(`${BASE_URL}/recommendations/checkAvailabilities`, {
    recommendationsIds,
    message,
  });
  if (res.ok) {
    MixpanelS.track(ConstantS.TrackingEvents.RecommendationAvailabilityRequested, {
      count: recommendationsIds.length,
    });
  }
  return res.ok;
};

const lookupCacheAvailability = async (
  contactId: number,
  eventId: number,
  eventDatesIds: number[],
): Promise<AvailabilityCache | null> => {
  const res = await ajaxActions.get(
    `${BASE_URL}/availabilityCache/latest?contactId=${contactId}&eventId=${eventId}&eventDatesIds=${eventDatesIds}`,
  );
  if (res.ok) {
    return res.json();
  }
  return null;
};

const getColourByAnswer = (reqAnswer: RequestAnswer) => {
  switch (reqAnswer) {
    case 'AVAILABLE':
      return '#4EBF46';
    case 'UNAVAILABLE':
      return '#f44336';
    case 'UNFIT':
      return '#f44336';
    case 'BLOCKED':
      return '#FDA632';
    case 'UNKNOWN':
      return 'gray';
    default:
      return 'gray';
  }
};

const getColourByBookingState = (bookingState: BookingState) => {
  switch (bookingState) {
    case 'OPEN':
      return '#1D5191';
    case 'OFFERED':
      return '#FDA632';
    case 'BOOKED':
      return '#4EBF46';
    case 'CANCELED':
      return '#f44336';
    default:
      return 'gray';
  }
};

// eslint-disable-next-line import/prefer-default-export
export const RecommendationS = {
  create,
  fetchById,
  fetchByEventId,
  edit,
  editAndInform,
  remove,
  removeEventRecommendations,
  sendInfoMail,
  checkAvailability,
  checkAvailabilities,
  getColourByAnswer,
  getColourByBookingState,
  lookupCacheAvailability,
};

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { ActionIcon, Button, Center, Group, Loader, Menu, Stack, Text, ThemeIcon } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import {
  IconBallpen,
  IconCheck,
  IconDots,
  IconNote,
  IconSend,
  IconServer,
  IconTrashX,
  IconUser,
  IconX,
} from '@tabler/icons';
import { MailgunDomainDTO, MailOutBoundConfigDTO } from '../../../Types/MailT';
import GrayBox from '../../../Atoms/GrayBox';
import { useJwtClaims } from '../../../Service/Context/AuthContext';
import { MailS } from '../../../Service/MailS';
import { openScheduleMeetingModal } from '../ContactFormBuilder/ScheduledMettingAlert';
import { isDefined } from '../../../Service/ValidationS';
import {
  openDeleteOutboundConfigModal,
  openDomainNotVerifiedModal,
  openMailAccountModal,
  openRevokeGrandModal,
} from './MailAccountModals';
import MailgunDomain from './mailgun/MailgunDomain';

interface Props {
  mailAccount: MailOutBoundConfigDTO;
  removeHandler: (cfgId: number) => void;
  editHandler: (cfg: MailOutBoundConfigDTO) => void;
}

const MailAccountPreview: FC<Props> = ({ mailAccount, removeHandler, editHandler }) => {
  const {
    id,
    smptHost,
    smptPort,
    description,
    startTls,
    auth,
    login,
    email,
    bcc,
    externalAccountId,
    verified,
    domainName,
  } = mailAccount;
  const { profileId } = useJwtClaims();
  const [sendingEmail, setSending] = useState(false);
  const [domain, setDomain] = useState<MailgunDomainDTO | null>();
  const sendTestMailAvailable = !domain || (domain && domain.verified);
  const [verifyingDomain, setVerifyingDomain] = useState(false);

  useEffect(() => {
    if (domainName) {
      MailS.getDomain(id).then(setDomain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainName]);

  const sendMail = async () => {
    setSending(true);
    const sent = await MailS.sendTestMail(id);
    setSending(false);
    if (!sent) {
      openScheduleMeetingModal();
    }
  };

  const verifyDomain = async () => {
    setVerifyingDomain(true);
    const resJSON = await MailS.verifyDomain(id);
    setVerifyingDomain(false);
    setDomain(resJSON);
    if (resJSON && resJSON.verified) {
      editHandler({ ...mailAccount, verified: true });
    } else {
      openDomainNotVerifiedModal();
    }
  };

  return (
    <>
      <GrayBox
        rightSection={
          <Menu shadow="md" width={150} transition="scale-y" position="bottom-end">
            <Menu.Target>
              <ActionIcon>
                <IconDots color="gray" style={{ cursor: 'pointer' }} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                icon={<IconBallpen size={16} />}
                onClick={() => openMailAccountModal({ profileId, mailAccount, onFinish: editHandler })}
              >
                Bearbeiten
              </Menu.Item>
              <Menu.Item
                color="red"
                icon={<IconTrashX size={16} />}
                onClick={() =>
                  openDeleteOutboundConfigModal({
                    config: mailAccount,
                    onDelete: () => {
                      if (externalAccountId) {
                        openRevokeGrandModal();
                      }
                      removeHandler(id);
                    },
                  })
                }
              >
                Löschen
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        }
      >
        <Text size="lg" weight="bolder" mb="lg">
          {email}
        </Text>
        <Stack mt="md" spacing="xs">
          {description && (
            <Text>
              <IconNote size={20} style={{ marginRight: 10, marginTop: -3 }} />
              {description}
            </Text>
          )}
          {!domainName && !externalAccountId && (
            <Text>
              <IconServer size={20} style={{ marginRight: 10, marginTop: -3 }} />
              {`${smptHost} : ${smptPort}`}
            </Text>
          )}

          {!domainName && isDefined(login) && login !== email && (
            <Text>
              <IconUser size={20} style={{ marginRight: 10, marginTop: -3 }} />
              {login}
            </Text>
          )}
          {isDefined(bcc) && (
            <Text weight="bolder" size="xs">
              BCC{' '}
              <Text span weight="normal" size="sm" style={{ marginLeft: 10 }}>
                {bcc}
              </Text>
            </Text>
          )}
        </Stack>
        {!domainName && !externalAccountId && (
          <Group spacing="xl" mt="md">
            <Text>
              <ThemeIcon radius="xl" color={startTls ? 'blue' : 'gray'} size="sm" mr="sm">
                {auth ? <IconCheck size={14} /> : <IconX size={14} />}
              </ThemeIcon>
              StartTLS
            </Text>
            <Text>
              <ThemeIcon radius="xl" color={auth ? 'blue' : 'gray'} size="sm" mr="sm">
                {auth ? <IconCheck size={14} /> : <IconX size={14} />}
              </ThemeIcon>
              SSL-Verschlüsselt
            </Text>
          </Group>
        )}
        {sendTestMailAvailable && (
          <Center mt="sm">
            <Button
              mt="xl"
              size="xs"
              leftIcon={sendingEmail ? <Loader size="xs" /> : <IconSend size={16} />}
              disabled={sendingEmail}
              onClick={sendMail}
            >
              Test-E-Mail verschicken
            </Button>
          </Center>
        )}
      </GrayBox>
      {domain && (
        <>
          <MailgunDomain domain={domain} />
          {!verified && (
            <Center>
              <Button
                size="xs"
                mt="xl"
                onClick={verifyDomain}
                disabled={verifyingDomain}
                leftIcon={verifyingDomain && <Loader size="xs" />}
              >
                Jetzt überprüfen
              </Button>
            </Center>
          )}
        </>
      )}
    </>
  );
};

export default MailAccountPreview;

export type MailOutBoundConfigDTO = {
  id: number;
  provider: string;
  type: MailConfigType;
  description: string;
  email: string;
  replyToEmail: string;
  smptHost: string;
  smptPort: number;
  login: string;
  password: string;
  auth: boolean;
  startTls: boolean;
  bcc: string;
  defaultAccount: boolean;
  externalAccountId: string | null;
  domainName: string | null;
  verified: boolean;
};

export type MailConfigType = 'SMTP' | 'SMTP_RELAY' | 'AZURE' | 'GOOGLE';

export const SkeletonMailOutBoundConfig = {
  provider: '',
  description: '',
  email: '',
  bcc: '',
  smptHost: '',
  smptPort: 587,
  login: '',
  password: '',
  auth: true,
  startTls: true,
};

export type ProviderHint = {
  provider: string;
  text?: string;
  appSpecificPasswordLink?: string;
  twoStepVerificationLink: string | null;
};

export type AzureAccountDTO = {
  id: string;
  email: string;
  scopes: string[];
};

export type DnsEntryDTO = {
  name: string;
  value: string;
  valid: boolean;
};

export type MailgunDomainDTO = {
  domainName: string;
  verified: boolean;
  dnsEntries: DnsEntryDTO[];
};

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Skeleton, Text } from '@mantine/core';
import { useEffect } from 'react';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import PanePaper from '../../../Molecules/PanePaper';
import { RoutingS } from '../../../Service/RoutingS';
import { useMailOutboundConfigs } from '../../../Service/hooks/useMailOutboundConfigs';
import EmptyText from '../../../Atoms/EmptyText';
import { useJwtClaims } from '../../../Service/Context/AuthContext';
import { openCreateConfigModal } from './MailAccountModals';
import MailAccountPreview from './MailAccountPreview';

const MailAccount = () => {
  const { mailOutboundConfigs, loading, append, removeConfig, editConfig } = useMailOutboundConfigs();
  const { profileId } = useJwtClaims();
  const { length } = mailOutboundConfigs;

  useEffect(() => {
    RoutingS.changeTitle('Ausgangsserver');
  }, []);

  return (
    <PanePaper
      sx={{ maxWidth: 650 }}
      title="settings.mail.account.outbound"
      tooltip="help.settings.mail.account.outbound.pane"
      rightSection={
        length < 1 && <BookitupAddIcon onClick={() => openCreateConfigModal({ profileId, onCreate: append })} />
      }
    >
      <Text mb="md">
        Hier kannst Du Deinen E-Mail-Account hinzufügen, damit E-Mails über Deinen E-Mail-Anbieter verschickt werden.
        Dadurch verringerst Du das Risiko, dass Deine E-Mails im Spam-Ordner Deiner Kunden landen.
      </Text>
      {loading ? (
        <Skeleton height={200} width="100%" />
      ) : (
        <>
          {length === 0 && <EmptyText />}
          {mailOutboundConfigs.map((config) => (
            <MailAccountPreview
              key={config.id}
              mailAccount={config}
              removeHandler={removeConfig}
              editHandler={editConfig}
            />
          ))}
        </>
      )}
    </PanePaper>
  );
};

export default MailAccount;

import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addPositionAtIndex, deletePositionAtIndex, movePosition, patchDocument } from '../restapi/documentService';
import ResourceAdapter from '../ResourceAdapter';
import { DocumentS } from '../../Templates/Documents/DocumentS';
import { DocumentService } from '../helper/DocumentService';
import { ProfileContext } from './ProfileContext';
import { ContactContext } from './ContactContext';
import { DocumentContactContextProviderProxy } from './DocumentContactContext';
import { ViolationContext } from './ViolationContext';

export const DocumentContext = React.createContext(undefined);

const DocumentContextProvider = (props) => {
  const [document, setDocument] = useState(props.document);
  const { interceptViolation } = useContext(ViolationContext);

  const [transmittingDocument, setTransmittingDocument] = useState(false);
  const [movingPosition, setMovingPosition] = useState(false);

  const { discount, positions, partialInvoices } = document;

  let sumAfterDiscount = 0;

  if (positions !== undefined) {
    positions.forEach((p) => {
      if (!p.optional) {
        sumAfterDiscount += p.price;
      }
    });
  }

  const { value: discountValue, type: discountType } = discount;
  const discountAmount = DocumentService.getDiscountAmount(discountType, discountValue, sumAfterDiscount);

  sumAfterDiscount -= discountAmount;

  const partialInvoicesSum = partialInvoices.reduce((acc, p) => acc + p.gross, 0);
  const negativeSum = sumAfterDiscount - partialInvoicesSum < 0;

  const refresh = () => {
    const { id: finalDocumentId } = document;
    DocumentS.fetchById(finalDocumentId).then((_doc) => {
      if (_doc) {
        setDocument(_doc);
      }
    });
  };

  return (
    <DocumentContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        movingPosition,
        document,
        sum: sumAfterDiscount,
        negativeSum,
        deletePosition: (index) =>
          deletePositionAtIndex(document.id, index)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((newDocument) => {
                  setDocument(newDocument);
                });
              } else {
                console.error(`could not delete position ${index} of document ${document.id}`);
              }
              return resp;
            })
            .catch((error) => {
              console.error({ error });
              console.error(`could not delete position ${index} of document ${document.id}`);
              return error;
            }),
        movePosition: (fromIndex, newIndex) => {
          setMovingPosition(true);
          movePosition(document.id, fromIndex, newIndex)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((newDocument) => {
                  setDocument(newDocument);
                });
              } else {
                console.error(
                  `could not move position for document ${document.id} from index ${fromIndex} to index ${newIndex}`,
                );
              }
            })
            .catch(() => {
              console.error(
                `could not move position for document ${document.id} from index ${fromIndex} to index ${newIndex}`,
              );
            })
            .finally(() => setMovingPosition(false));
        },
        savePatch: (patch, onSuccess, onError) => {
          patchDocument(document.id, patch)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then(setDocument);
                if (onSuccess) {
                  onSuccess();
                }
              } else {
                if (onError) {
                  onError(resp);
                }
                interceptViolation(resp);
                console.error(`could not patch document ${document.id}`, { patch });
              }
            })
            .catch(({ error }) => {
              console.error({ error });
              console.error(`could not patch document ${document.id}`, { patch });
            });
        },
        addPosition: (index) => {
          if (index === undefined) {
            index = document.positions.length;
          }
          return addPositionAtIndex(document.id, index)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((newDocument) => {
                  setDocument(newDocument);
                });
              } else {
                console.error(`could not add position for document ${document.id} at index ${index}`);
              }
              return resp;
            })
            .catch((error) => {
              console.error(`could not add position for document ${document.id} at index ${index}`);
              return error;
            });
        },
        transmittingDocument,
        setTransmittingDocument,
        refresh,
        handleChange: (patch) => {
          // automatisches setzen von "Gültig bis", anhand der Einstellung in den Einstellungen (valid until).
          /* if (patch.date !== undefined) {
                    let date = new Date(patch.date);
                    let newValidUntil = new Date(date.getFullYear(), date.getMonth(), date.getDate() + profile.offerValidUntil, 12);
                    patch.validUntil = newValidUntil
                }

                if (patch.validUntil !== undefined) {
                    let daysTilExpire = new Date(patch.validUntil).getDate() - new Date(patch.date).getDate();
                    patch.daysTilExpire = daysTilExpire;
                } */
          if (patch && Object.keys(patch).length === 1 && patch.positions) {
            refresh();
          } else {
            setDocument((prevState) => ({
              ...prevState,
              ...patch,
            }));
          }
        },
      }}
    >
      <DocumentContactContextProviderProxy />
    </DocumentContext.Provider>
  );
};

export const DocumentContextProviderProxy = () => {
  const { profile } = useContext(ProfileContext);
  const { contact } = useContext(ContactContext);
  const { documentId } = useParams();

  if (documentId === 'new') {
    const initDocument = DocumentS.initDocument(contact.id, profile.offerValidUntil);

    return <DocumentContextProvider document={initDocument} />;
  }
  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/documents/${documentId}`}
      component={(props) => {
        const document = props.data;
        document.positions.forEach((pos) => {
          if (pos.subtext) {
            pos.subtext = pos.subtext.replace(/<br\/>/g, '\n');
          }
        });
        return <DocumentContextProvider document={document} />;
      }}
    />
  );
};

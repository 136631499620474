// eslint-disable-next-line no-redeclare
import { Flex, Group, Space, Stack, Text } from '@mantine/core';
import { useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd-latest';
import { openModal } from '@mantine/modals';
import { IconTrashX } from '@tabler/icons';
import { useElementSize } from '@mantine/hooks';
import { FocusedNodeContext } from '../../../Service/Context/FocusedNode';
import DragButton from '../../../Atoms/DragDrop/DragButton';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { Position } from './Position';
import { DocumentPositionPreview, ProductPositionPreview } from './PositionPreview';
import PositionModal, { openDeletePositionModal } from './PositionModals';
import { DocumentPositionActions } from './PositionActions';

// eslint-disable-next-line import/prefer-default-export
export const DocumentPosition = ({
  position,
  index,
  favorites,
  fetchFavorites,
  changePosition,
  deletePosition,
  addPosition,
  movePosition,
  _document,
  ...rest
}) => {
  const { setListPosition } = useContext(FocusedNodeContext);
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { profile } = useContext(ProfileContext);
  const { ref, width } = useElementSize();
  const { settings, event, customer, eventLocation } = rest;
  const { taxType, documentType } = _document;
  const isPartialInvoice = documentType === 'PARTIAL_INVOICE';

  const usePreviewVersion = width < 800;

  const [, drop] = useDrop(
    () => ({
      accept: 'documentPosition',
      collect(monitor) {
        return { handlerId: monitor.getHandlerId() };
      },
      drop(item, monitor) {
        console.log(`move position ${item.position.label} to index ${index}`);
        movePosition(item.index, index);
      },
    }),
    [position.id],
  );

  const [collected, drag] = useDrag(
    () => ({
      type: 'documentPosition',
      item: { position, index },
    }),
    [position.id],
  );

  const editPosition = () => {
    openModal({
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Position
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: (
        <PositionModal
          index={index}
          position={position}
          favorites={favorites}
          changePosition={changePosition}
          _document={_document}
          profile={profile}
          executeWithLicenceCheck={executeWithLicenceCheck}
          {...rest}
        />
      ),
    });
  };

  return (
    <div
      ref={drop}
      style={{ border: '1px solid #D4D4D4', padding: 10, borderRadius: 6, background: 'rgba(251, 251, 251, 0.9)' }}
    >
      <div ref={ref} onClick={() => setListPosition(index)}>
        {usePreviewVersion ? (
          <Flex>
            <div style={{ marginLeft: 5, flexGrow: 1 }}>
              {settings ? (
                <ProductPositionPreview position={position} />
              ) : (
                <DocumentPositionPreview
                  position={position}
                  taxType={taxType}
                  event={event}
                  document={_document}
                  customer={customer}
                  location={eventLocation}
                />
              )}
            </div>
            <div>
              <DocumentPositionActions
                previewVersion
                addPosition={addPosition}
                movePosition={movePosition}
                index={index}
                position={position}
                deletePosition={() => openDeletePositionModal({ onConfirm: () => deletePosition(index) })}
                editPosition={editPosition}
                positionCount={_document.positions.length}
                fetchFavoritePositions={fetchFavorites}
                isPartialInvoice={isPartialInvoice}
              />
            </div>
          </Flex>
        ) : (
          <Group>
            {!isPartialInvoice && <DragButton dragRef={drag} entity="position" />}
            {isPartialInvoice && <div style={{ width: 20 }} />}
            <Position
              index={index}
              position={position}
              favorites={favorites}
              changePosition={changePosition}
              fetchFavorites={fetchFavorites}
              _document={_document}
              {...rest}
            />
            {!isPartialInvoice && (
              <Stack ml="xs" justify="space-between">
                <DocumentPositionActions
                  addPosition={addPosition}
                  movePosition={movePosition}
                  index={index}
                  position={position}
                  deletePosition={deletePosition}
                  positionCount={_document.positions.length}
                  fetchFavoritePositions={fetchFavorites}
                />
                <Space h="xl" />
                <IconTrashX
                  size={20}
                  color="gray"
                  onClick={() => openDeletePositionModal({ onConfirm: () => deletePosition(index) })}
                  style={{ cursor: 'pointer' }}
                />
              </Stack>
            )}
            {isPartialInvoice && <div style={{ width: 35 }} />}
          </Group>
        )}
      </div>
    </div>
  );
};

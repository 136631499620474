/* eslint-disable react/jsx-props-no-spreading */
import { Box, BoxProps } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface Props extends BoxProps {
  children: ReactNode;
  rightSection?: ReactNode;
}

const GrayBox: FC<Props> = ({ children, rightSection, ...rest }) => (
  <Box pos="relative" p="md" sx={{ borderRadius: 10, backgroundColor: '#F1F1F1' }} {...rest}>
    {children}
    {rightSection && <Box sx={{ position: 'absolute', right: 10, top: 10 }}>{rightSection}</Box>}
  </Box>
);

export default GrayBox;

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Text, Space, LoadingOverlay, ThemeIcon, Tooltip } from '@mantine/core';
import { IconCheck, IconTrashX } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { openModal, openConfirmModal } from '@mantine/modals';
import BookitupTable from '../../../../Atoms/BookitupTable';
import { DocumentPayment, DocumentPaymentS } from '../../../../Service/DocumentPaymentS';
import { FormatS } from '../../../../Service/FormatS';
import BookitupDocument from '../../../../Types/BookitupDocument';
import { ToastS } from '../../../../Service/ToastS';
import CancelPaymentModal from './CancelPaymentModal';
import AddPayment from './AddPayment';

const PaymentsManagerModal: FC<{
  bookitupDocument: BookitupDocument;
  interceptViolation: (httpResponse: Response) => void;
}> = ({ bookitupDocument, interceptViolation }) => {
  const [loadingPayments, setLoadingPayments] = useState<boolean>(false);
  const [payments, setPayments] = useState<DocumentPayment[]>([]);
  const { id: documentId, finalSum: documentSum } = bookitupDocument;
  const unpaidSum =
    documentSum -
    payments.reduce((acc, p) => {
      if (!p.cancelled) {
        acc += p.value;
      }
      return acc;
    }, 0);
  const paid = unpaidSum === 0;
  const allowZeroPayment = documentSum === 0 && payments.length === 0;

  useEffect(() => {
    loadDocumentPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  const loadDocumentPayments = () => {
    setLoadingPayments(true);
    DocumentPaymentS.getPayments(documentId)
      .then((_payments) => {
        if (_payments) {
          setPayments(_payments);
        }
      })
      // TODO: Some UI for error handling?!
      .finally(() => setLoadingPayments(false));
  };

  const removePayment = (paymentId: string) => {
    if (paid) {
      openModal({
        modalId: paymentId,
        size: 'sm',
        title: (
          <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
            Zahlung stornieren
          </Text>
        ),
        closeOnEscape: true,
        transition: 'slide-down',
        children: <CancelPaymentModal paymentId={paymentId} interceptViolation={interceptViolation} />,
      });
    } else {
      openConfirmModal({
        size: 'sm',
        title: (
          <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
            Zahlung stornieren
          </Text>
        ),
        closeOnEscape: true,
        transition: 'slide-down',
        labels: { confirm: 'Ja', cancel: 'Abbrechen' },
        children: <Text>Möchten Sie die Zahlung wirklich stornieren?</Text>,
        onConfirm: () => {
          DocumentPaymentS.cancelPayment(paymentId, {}).then((res) => {
            if (res.ok) {
              loadDocumentPayments();
            } else {
              ToastS.generalError();
            }
          });
        },
      });
    }
  };

  const mapPaymentUI = (_payment: DocumentPayment) => {
    const { id, payDate, value, cancelled, cancellationCause } = _payment;
    return (
      <Tooltip
        withArrow
        disabled={!cancelled}
        transition="slide-down"
        position="bottom-start"
        label={
          <>
            Stornonotiz:{' '}
            <Text italic size="xs">
              {cancellationCause}
            </Text>
          </>
        }
      >
        <tr>
          <td>
            <Text td={cancelled ? 'line-through' : 'none'}>
              <Moment
                date={payDate}
                format="DD.MM.YYYY"
                style={{ textDecoration: cancelled ? 'line-through' : 'none' }}
              />
            </Text>
          </td>
          <td>
            <Text td={cancelled ? 'line-through' : 'none'}>{FormatS.formatNumberCurrencyWithUnitSign(value)}</Text>
          </td>
          <td style={{ textAlign: 'right' }}>
            <IconTrashX
              size={16}
              color="gray"
              onClick={() => removePayment(id)}
              style={{ cursor: cancelled ? 'not-allowed' : 'pointer', opacity: cancelled ? 0.4 : 1 }}
            />
          </td>
        </tr>
      </Tooltip>
    );
  };

  return (
    <>
      <LoadingOverlay visible={loadingPayments} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Text sx={{ float: 'right' }}>
        {paid && !allowZeroPayment ? (
          <Group mt="md">
            <ThemeIcon radius="xl" size="sm">
              <IconCheck size={14} />
            </ThemeIcon>
            Bezahlt
          </Group>
        ) : (
          <>
            Offen:{' '}
            <Text span weight="lighter">
              {FormatS.formatNumberCurrencyWithUnitSign(unpaidSum)}
            </Text>{' '}
          </>
        )}
      </Text>
      <Text weight="lighter">Bisher erfasste Zahlungen</Text>
      <Space h="xl" />
      {payments.length > 0 ? (
        <BookitupTable>
          <thead>
            <tr>
              <th style={{ width: 120 }}>Datum</th>
              <th style={{ width: 100 }}> Betrag </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>{payments.map(mapPaymentUI)}</tbody>
        </BookitupTable>
      ) : (
        <Text italic color="dimmed" size="xs" weight="lighter">
          Noch nichts hinzugefügt.
        </Text>
      )}
      <Space h={50} />
      {(!paid || allowZeroPayment) && (
        <AddPayment
          maxAmount={unpaidSum}
          refresh={loadDocumentPayments}
          documentId={documentId}
          allowZeroPayment={allowZeroPayment}
        />
      )}
    </>
  );
};

export default PaymentsManagerModal;

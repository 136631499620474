// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Code, Group, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { FC } from 'react';
import { IconCheck, IconX } from '@tabler/icons';
import { DnsEntryDTO, MailgunDomainDTO } from '../../../../Types/MailT';
import CodeWithCopy from '../../../../Atoms/CodeWithCopy';
import MailgunVerificationAlert from './MailgunVerificationAlert';

const MailgunDomain: FC<{ domain: MailgunDomainDTO }> = ({ domain }) => {
  const { domainName, dnsEntries, verified } = domain;

  return (
    <>
      <Text mt="md" size="md" weight="bolder">
        SMTP Relay
      </Text>
      <Group mt="lg" spacing="xs">
        {verified && (
          <ThemeIcon radius="xl" size="xs">
            <IconCheck />
          </ThemeIcon>
        )}
        {!verified && (
          <Tooltip label="Nicht verifiziert">
            <ThemeIcon radius="xl" size="xs" color="gray">
              <IconX />
            </ThemeIcon>
          </Tooltip>
        )}
        <Text weight="bolder">{domainName}</Text>
      </Group>
      {!verified && <MailgunVerificationAlert />}
      <Text mt="md">
        Fügen Sie die folgenden DNS-Einträge zu Ihren Domäneneinträgen hinzu. Überprüfen Sie dann die Domäne.
        Berücksichtigen Sie, dass Änderungen an Ihrer Domain einige Zeit zum Nachdenken in Anspruch nehmen.
      </Text>
      <Text mt="xl" mb="md" size="md" weight="bolder">
        DNS-Einträge
      </Text>
      {dnsEntries?.map((entry: any) => (
        <DnsEntry key={entry.name} entry={entry} />
      ))}
    </>
  );
};

const DnsEntry: FC<{ entry: DnsEntryDTO }> = ({ entry }) => {
  const { valid, name, value } = entry;

  const getIcon = () => {
    if (valid) {
      return (
        <ThemeIcon radius="xl" size="xs">
          <IconCheck />
        </ThemeIcon>
      );
    }
    return (
      <Tooltip label="Nicht verifiziert">
        <ThemeIcon radius="xl" size="xs" color="gray">
          <IconX />
        </ThemeIcon>
      </Tooltip>
    );
  };

  return (
    <>
      <Group mt="md" spacing="xs" mb={5}>
        {getIcon()}
        <Text weight="bolder">Name</Text>
      </Group>
      <CodeWithCopy content={name} />
      <Text mb={5} mt="xs" weight="bolder">
        Typ
      </Text>
      <Code block>TXT</Code>
      <Text mb={5} mt="xs" weight="bolder">
        Wert
      </Text>
      <CodeWithCopy content={value} />
    </>
  );
};

export default MailgunDomain;

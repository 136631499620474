// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Space, Group, NumberInput, Loader, Text, Button, Center } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { IconCurrencyEuro, IconCalendarEvent } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';
import { DocumentPaymentS } from '../../../../Service/DocumentPaymentS';
import { ToastS } from '../../../../Service/ToastS';
import { TestS } from '../../../../TestS';

interface Props {
  documentId: number;
  maxAmount: number;
  refresh: () => void;
  allowZeroPayment: boolean;
}

const AddPayment: FC<Props> = ({ documentId, maxAmount, refresh, allowZeroPayment }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<number>(maxAmount);
  const [payDate, setPayDate] = useState<Date>(new Date());

  useEffect(() => {
    if (maxAmount) {
      setValue(maxAmount);
    }
  }, [maxAmount]);

  const addPayment = () => {
    setLoading(true);
    DocumentPaymentS.addPayment(documentId, { value, payDate })
      .then((newPayment) => {
        if (newPayment) {
          refresh();
          setValue(0);
        } else {
          ToastS.generalError();
        }
      })
      .finally(() => setLoading(false));
  };

  const addPaymentAllowed = !loading && (value > 0 || allowZeroPayment);

  return (
    <>
      <Text weight="lighter">Eine neue Zahlung erfassen</Text>
      <Space h="xs" />
      <Group position="center">
        <NumberInput
          hideControls
          label={<Text size="xs">Betrag</Text>}
          min={0}
          max={maxAmount}
          precision={2}
          rightSection={<IconCurrencyEuro size={16} />}
          value={value}
          onChange={(val) => val && setValue(val)}
          sx={{ maxWidth: 100 }}
          data-testid={TestS.Documents.ManagePayments.AMOUNT_INPUT}
        />
        <DatePicker
          icon={<IconCalendarEvent size={16} />}
          value={payDate}
          onChange={(val) => val && setPayDate(val)}
          label={<Text size="xs">Datum</Text>}
          maxDate={new Date()}
          sx={{ maxWidth: 150 }}
          data-testid={TestS.Documents.ManagePayments.DATE_INPUT}
        />
      </Group>
      <Space h="lg" />
      <Center>
        <Button
          onClick={addPayment}
          disabled={!addPaymentAllowed}
          leftIcon={loading && <Loader size="xs" />}
          data-testid={TestS.Documents.ManagePayments.ADD}
        >
          Zahlung buchen
        </Button>
      </Center>
    </>
  );
};

export default AddPayment;

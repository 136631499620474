/* eslint-disable no-console */

import { ht } from 'date-fns/locale';
import { EventsFilter } from '../Types/Event';

/* eslint-disable no-undef */
interface NotificationStatus {
  supported: boolean;
  granted: boolean;
}

/**
 * Verify whenever Notification is supported in browser.
 * Prevents blank page for iPhone/iPad devices
 */
const getNotificationStatus = (): NotificationStatus => {
  if ('Notification' in window) {
    return { supported: true, granted: Notification.permission === 'granted' };
  }
  return { supported: false, granted: false };
};

const registerNotificationWorker = () => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker
      .register('/notifications.worker.js')
      .catch((error) => console.error('Service Worker registration failed:', error));
  }
};

/**
 * Let's show notifications within the sevice worker instead of using Notifications API.
 * Notifications API are not working for mobile devices.
 * https://developer.mozilla.org/en-US/docs/Web/API/Notification
 */

const showNotification = (title: string, opts?: NotificationOptions) => {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.ready.then((registration) => {
      if (registration.active) {
        registration.active.postMessage({ type: 'notification', title, opts });
      }
    });
  }
};

const EVENTS_FILTER = 'eventsFilter';

const loadEventsFilter = (): EventsFilter | null => {
  const filter = localStorage.getItem(EVENTS_FILTER);
  if (filter) {
    return JSON.parse(filter) as EventsFilter;
  }
  return null;
};

const saveEventsFilter = (filter: EventsFilter) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { from, to, profiles, ...rest } = filter;
  localStorage.setItem(EVENTS_FILTER, JSON.stringify(rest));
};

const deleteEventsFilter = () => localStorage.removeItem(EVENTS_FILTER);

const extractDomain = (url: string) => {
  try {
    // Create a URL object to easily parse the URL
    const parsedUrl = new URL(url);
    // Return only the hostname (domain)
    return parsedUrl.hostname;
  } catch (error) {
    return null; // Handle invalid URLs gracefully
  }
};

const getTextContent = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

// eslint-disable-next-line import/prefer-default-export
export const BrowserS = {
  showNotification,
  getNotificationStatus,
  registerNotificationWorker,
  loadEventsFilter,
  saveEventsFilter,
  deleteEventsFilter,
  extractDomain,
  getTextContent,
};

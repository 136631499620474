// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, Text } from '@mantine/core';
import { IconCalendarTime } from '@tabler/icons';
import { RoutingS } from '../../../../Service/RoutingS';

const MailgunVerificationAlert = () => (
  <Alert title="Hilfe erforderlich?" sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })} my="md">
    <Text>
      Hast du Probleme beim Einrichten? Mach einfach einen Termin mit unserem Technik-Support und wir lösen das Problem
      gemeinsam.
    </Text>
    <Button
      size="xs"
      leftIcon={<IconCalendarTime size={16} />}
      mt="md"
      onClick={() => RoutingS.openInNewTab('https://hey.bookitup.de/meetings/sebastian-mahlke/technik-support')}
    >
      Termin vereinbaren
    </Button>
  </Alert>
);

export default MailgunVerificationAlert;

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Center, Divider, Flex, Paper, Skeleton, Stack, Text, Tooltip } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { FC, MouseEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconClock, IconExternalLink } from '@tabler/icons';
import { useElementSize } from '@mantine/hooks';
import { WindowContext } from '../../Service/Context/WindowContext';
import { BookingEventDateItem } from '../../Types/Event';
import { RoutingS } from '../../Service/RoutingS';
import { SpanDot } from '../../Atoms/Dot';
import { Contact, ContactS } from '../../Service/ContactS';
import { EventsContext } from '../../Service/Context/EventsContext';
import { isDefined } from '../../Service/ValidationS';
import LocationDescription from './LocationDescription';

const EventAppointmentItem: FC<{ bookingEventDate: BookingEventDateItem }> = ({ bookingEventDate }) => {
  const { query, filter } = useContext(EventsContext);
  const { sorting } = filter;
  const { i18n } = useLingui();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<Contact>();
  const { xs, sm } = useContext(WindowContext);
  const { ref, width } = useElementSize();
  const { eventId, kind, kindColor, customerId, start, weekday, timeFrom, timeTo, locationId, subject } =
    bookingEventDate;
  const eventDate = new Date(start);
  const eventDay = eventDate.getDate();
  const customerMissing = !loading && !customer;
  const hideTitle =
    sorting !== 'createdAt' && isDefined(query) && customer && !ContactS.matchQuery(customer, query as string);

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();
    ContactS.fetchById(customerId)
      .then((resJSON) => {
        if (resJSON) {
          setCustomer(resJSON);
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
    return () => abortController.abort();
  }, [customerId]);

  if (hideTitle) {
    return null;
  }

  const getCustomerDescription = () => {
    if (loading) {
      return <Skeleton height={25} width={250} />;
    }
    if (customer) {
      return ContactS.getDescription(customer);
    }
    return i18n._('errors.contact.not.synced');
  };

  const openInNewTab = (e: MouseEvent<SVGElement>) => {
    e.stopPropagation(); // Prevent bookitup event page to be opened
    RoutingS.openInNewTab(`${process.env.REACT_APP_FRONTEND_URL}/events/${eventId}/details`);
  };

  return (
    <Paper
      ref={ref}
      className="event-list-item"
      shadow="xs"
      radius={10}
      p="md"
      onClick={() => navigate(`/events/${eventId}/details`)}
      sx={(theme) => ({
        cursor: customerMissing ? 'not-allowed' : 'pointer',
        opacity: customerMissing ? 0.5 : 1,
        '&:hover': {
          backgroundColor: theme.colors.lightBlue[0],
        },
        backgroundColor: theme.colors.secondaryBlue[4],
      })}
    >
      <Box sx={{ display: 'flex', minHeight: 30, position: 'relative', gap: 15 }}>
        <div style={{ display: 'flex', flexDirection: sm ? 'column' : 'row', alignItems: 'center', gap: 10 }}>
          <Text size="xs" transform="uppercase" weight="lighter">
            {weekday}
          </Text>
          <Text size={20} weight="bold">{`${eventDay < 10 ? '0' : ''}${eventDay}`}</Text>
          <Text size="xs" weight="lighter">{`${i18n
            ._(`month.${eventDate.getMonth() + 1}`)
            .toUpperCase()
            .substring(0, 3)}`}</Text>
        </div>
        <Divider orientation="vertical" />
        <Stack spacing={5}>
          <Text size="md" weight="bolder" className="overflow-ellipsis" sx={{ maxWidth: width - (xs ? 100 : 150) }}>
            {`${subject ?? ''} mit ${getCustomerDescription()}`}
          </Text>
          <Flex wrap="wrap" gap={xs ? 2 : 'xs'} direction={xs ? 'column' : 'row'}>
            {kind && (
              <Text weight="lighter" size="xs" color="dimmed">
                {kindColor && <SpanDot withoutShadow colour={kindColor} />}
                <b>{kind}</b>
              </Text>
            )}
            {kind && timeFrom && timeTo && (
              <>
                <Divider orientation="vertical" />
                <Text span weight="lighter" size="xs" color="dimmed">
                  <IconClock size={16} color="grey" style={{ marginRight: 5, marginTop: -3 }} />
                  {timeFrom && timeTo && `${timeFrom} - ${timeTo}`}
                </Text>
              </>
            )}
            {locationId && (
              <>
                {(kind || (timeFrom && timeTo)) && <Divider orientation="vertical" />}
                <LocationDescription locationId={locationId} />
              </>
            )}
          </Flex>
        </Stack>
        <Center>
          <Tooltip withArrow position="left" transition="slide-left" label="Im neuen Tab öffnen">
            <Box sx={{ position: 'absolute', right: 0 }}>
              <IconExternalLink onClick={openInNewTab} />
            </Box>
          </Tooltip>
        </Center>
      </Box>
    </Paper>
  );
};

export default EventAppointmentItem;

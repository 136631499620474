import React, { useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd-latest';
import { HTML5Backend } from 'react-dnd-html5-backend-latest';
import { WindowContext } from '../../Service/Context/WindowContext';
import { fetchFavoritePositions } from '../../Service/restapi/positionService';
import { DocumentContext } from '../../Service/Context/DocumentContext';
import { DocumentPosition } from './Position/DocumentPosition';
import { Box, LoadingOverlay, Stack } from '@mantine/core';

export const PositionList = ({ _document, handleChange, ...rest }) => {
  const [favorites, setFavorites] = useState([]);
  const { windowWidth } = useContext(WindowContext);
  const { positions } = _document;

  useEffect(() => {
    fetchFavorites();
  }, []);

  function fetchFavorites() {
    fetchFavoritePositions()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((favorites) => {
            setFavorites(favorites);
          });
        } else {
          console.error('could not load position favorites');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not load position favorites');
      });
  }

  function changePosition(index, position) {
    _document.positions[index] = position;
    handleChange({ positions: _document.positions });
  }

  if (!positions || positions.length === 0) {
    return null;
  }

  return (
    <Stack>
      {_document.positions.map((val, index) => (
        <DocumentPosition
          key={index}
          position={val}
          changePosition={changePosition}
          index={index}
          favorites={favorites}
          _document={_document}
          {...rest}
        />
      ))}
    </Stack>
  );
};

const PositionListWithDocumentContext = ({
  preChange,
  onPlaceholderClicked,
  customer,
  event,
  eventLocation,
  customAttributeValues,
  settings,
  distance,
}) => {
  const { document, handleChange, deletePosition, addPosition, movePosition, movingPosition } =
    useContext(DocumentContext);

  return (
    <DndProvider backend={HTML5Backend}>
      <Box pos="relative">
        <LoadingOverlay visible={movingPosition} overlayBlur={2} loaderProps={{ size: 'xl' }} />
        <PositionList
          preChange={preChange}
          onPlaceholderClicked={onPlaceholderClicked}
          _document={document}
          handleChange={handleChange}
          deletePosition={deletePosition}
          addPosition={addPosition}
          movePosition={movePosition}
          customer={customer}
          event={event}
          eventLocation={eventLocation}
          distance={distance}
          customAttributeValues={customAttributeValues}
          settings={settings}
        />
      </Box>
    </DndProvider>
  );
};

export default PositionListWithDocumentContext;

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Group, Menu, Skeleton, Text } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { IconChevronDown } from '@tabler/icons';
import moment from 'moment';
import Moment from 'react-moment';
import { AvailabilityCache, RecommendationDto, RequestAnswer } from '../../../Types/RecommendationT';
import Dot from '../../../Atoms/Dot';
import { RecommendationS } from '../../../Service/RecommendationS';
import { checkForeignCalendarAvailability } from '../../../Service/restapi/calendarAccountService';
import { EventDateDTO } from '../../../Types/MessageT';
import { RecommendationContext } from './RecommendationsPane';

const RecommendationAvailability: FC<{
  recommendation: RecommendationDto;
  setAvailabilityConfirmed: (val: boolean) => void;
}> = ({ recommendation, setAvailabilityConfirmed }) => {
  const {
    id: recommendationId,
    answer,
    recommendedContactId,
    lastAnswered,
    eventId,
    lastRequested,
    eventDatesIds,
  } = recommendation;
  const { patchRecommendation } = useContext(RecommendationContext);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [loadingCaches, setLoadingCaches] = useState(false);
  const [availabilityCache, setAvailabilityCache] = useState<AvailabilityCache>();
  const [clashExists, setClashExists] = useState<boolean>();
  const [finalAnswer, setFinalAnswer] = useState(recommendation.answer);

  const changeAnswer = (_answer: RequestAnswer) => {
    if (_answer !== answer) {
      setLoadingCalendar(true);
      RecommendationS.edit(recommendationId, { answer: _answer })
        .then((_recommendation) => _recommendation && patchRecommendation(_recommendation))
        .finally(() => setLoadingCalendar(false));
    }
  };

  useEffect(() => {
    if (clashExists !== undefined) {
      if (clashExists) {
        setFinalAnswer('UNAVAILABLE');
      } else {
        setFinalAnswer('AVAILABLE');
      }
    } else if (availabilityCache) {
      setFinalAnswer(availabilityCache.requestAnswer);
    }
  }, [clashExists, availabilityCache]);

  useEffect(() => {
    if (finalAnswer === 'AVAILABLE') {
      setAvailabilityConfirmed(true);
    }
  }, [finalAnswer, setAvailabilityConfirmed]);

  useEffect(() => {
    if (answer !== finalAnswer) {
      setFinalAnswer(answer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  useEffect(() => {
    if (answer === 'UNKNOWN') {
      setLoadingCaches(true);
      RecommendationS.lookupCacheAvailability(recommendedContactId, eventId, eventDatesIds)
        .then((_availabilityCache) => {
          if (_availabilityCache) {
            setAvailabilityCache(_availabilityCache);
          }
        })
        .finally(() => setLoadingCaches(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, recommendedContactId]);

  useEffect(() => {
    if (answer === 'UNKNOWN') {
      setLoadingCalendar(true);
      checkForeignCalendarAvailability(recommendedContactId, eventId, eventDatesIds)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((calendarEvents) => {
              const bookedBusinessEvents = calendarEvents.businessEvents.filter(
                (e: EventDateDTO) => e.bookingState === 'BOOKED',
              );
              const clash = calendarEvents.privateEvents.length > 0 || bookedBusinessEvents.length > 0;
              setClashExists(clash);
            });
          }
        })
        .finally(() => {
          setLoadingCalendar(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendedContactId]);

  if (loadingCalendar || loadingCaches) {
    return <Skeleton height={30} radius="md" />;
  }

  return (
    <Box
      p={5}
      pr="xs"
      pl="md"
      sx={(theme) => ({ borderRadius: theme.spacing.xs, backgroundColor: 'white', fontSize: theme.fontSizes.xs })}
    >
      <Group position="apart">
        <Group>
          <Dot colour={RecommendationS.getColourByAnswer(finalAnswer)} />
          <Text weight="bolder">{i18n._(`recommendation.answer.${finalAnswer}`)}</Text>
        </Group>
        <Menu shadow="md" radius="md" width={170} position="bottom-end" transition="scale-y">
          <Menu.Target>
            <div>
              <IconChevronDown size={16} style={{ cursor: 'pointer' }} />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            {['AVAILABLE', 'BLOCKED', 'UNAVAILABLE', 'UNFIT', 'UNKNOWN'].map((_answer) => (
              <Menu.Item
                key={_answer}
                icon={<Dot colour={RecommendationS.getColourByAnswer(_answer as RequestAnswer)} />}
                onClick={() => changeAnswer(_answer as RequestAnswer)}
              >
                {i18n._(`recommendation.answer.${_answer}`)}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Group>
      {lastRequested && !lastAnswered && (
        <Text italic color="dimmed">
          Anfrage gesendet vor <Moment fromNow>{lastRequested}</Moment>
        </Text>
      )}
      {lastAnswered && (
        <Text italic color="dimmed">
          Angefragt <Moment fromNow>{lastAnswered}</Moment>
        </Text>
      )}
      {clashExists !== undefined && (
        <Text italic color="dimmed">
          Verfügbarkeit laut Kalenderabgleich
        </Text>
      )}
      {clashExists === undefined && availabilityCache && (
        <Text italic color="dimmed">
          {`Verfügbarkeit laut Antwort vom ${moment(availabilityCache.lastModified).format('DD.MM.YY HH:MM')}`}
        </Text>
      )}
    </Box>
  );
};

export default RecommendationAvailability;

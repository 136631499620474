// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, Center, Text } from '@mantine/core';
import { IconCalendarTime, IconQuestionCircle } from '@tabler/icons';
import { closeAllModals, openModal } from '@mantine/modals';
import { RoutingS } from '../../../Service/RoutingS';

export const openScheduleMeetingModal = () =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Der E-Mail-Versand ist fehlgeschlagen
      </Text>
    ),
    children: (
      <>
        <Text>
          Hast du Probleme beim Einrichten? Mach einfach einen Termin mit unserem Technik-Support und wir lösen das
          Problem gemeinsam.
        </Text>
        <Center>
          <Button
            leftIcon={<IconCalendarTime size={16} />}
            mt="md"
            onClick={() => {
              RoutingS.openInNewTab('https://hey.bookitup.de/meetings/sebastian-mahlke/technik-support');
              closeAllModals();
            }}
          >
            Termin vereinbaren
          </Button>
        </Center>
      </>
    ),
  });

const ScheduleMeetingAlert = () => (
  <Alert
    icon={<IconQuestionCircle />}
    title="Hilfe erforderlich?"
    sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}
  >
    <Text>
      Hast du Probleme beim Einrichten? Mach einfach einen Termin mit unserem Technik-Support und wir lösen das Problem
      gemeinsam.
    </Text>
    <Button
      size="xs"
      leftIcon={<IconCalendarTime size={16} />}
      mt="md"
      onClick={() => RoutingS.openInNewTab('https://hey.bookitup.de/meetings/sebastian-mahlke/technik-support')}
    >
      Termin vereinbaren
    </Button>
  </Alert>
);

export default ScheduleMeetingAlert;

import { useState, useEffect, useCallback } from 'react';
import { SubscriptionDTO } from '../../Types/Billing';
import SubscriptionS from '../restapi/subscriptionService';
import { useJwtClaims } from '../Context/AuthContext';
import { LicenceS } from '../helper/LicenceService';

// eslint-disable-next-line import/prefer-default-export
export const useBookitupSubscription = () => {
  const [subscription, setSubscription] = useState<SubscriptionDTO>();
  const [loading, setLoading] = useState(false);
  const { licence } = useJwtClaims();
  const freeLicence = LicenceS.isFreeLicence(licence);
  const { addons, status } = subscription ?? {};
  const resourceAddon = addons && addons.length > 0 ? addons[0] : null;
  const isActive = status === 'ACTIVE';

  const fetchSubscriptionDetails = useCallback(() => {
    setLoading(true);
    SubscriptionS.getSubscription()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setSubscription);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchSubscriptionDetails();
  }, [fetchSubscriptionDetails, freeLicence]);

  return { subscription, isActive, loading, refresh: fetchSubscriptionDetails, resourceAddon };
};

import { BankAccountDTO } from '../Types/AuthT';
import { ajaxActions } from './AjaxActions';
import { BrowserS } from './BrowserS';
import { Licence } from './Context/AuthContext';

export type Profile = {
  id: number;
  applicationUserId: number;
  smallScaleBusiness: boolean;
  dismissOnboarding: boolean;
  vat: number;
  vatStatement: string;
  supportAccess: boolean;
  gdprAccepted: boolean;
  addressOfWebsite: string;
  addressOfFacebook: string;
  addressOfInstagram: string;
  alias: string;
  contactId: number;
  taxOffice?: string;
  taxNumber?: string;
  vatId?: string;
  signature: string;
  kindOfWebsite: string;
  fixEngagementUntil: number;
  offerValidUntil: number;
  monthlyTurnover: number;
  shares: number[];
  services: string[];
  accountingTool?: string;
  lastModified: Date;
  licence: Licence;
};

const BASE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

const patchProfile = (profileId: number, patch: Partial<Profile>): Promise<Profile | null> =>
  ajaxActions.patch(`${BASE_URL}/profiles/${profileId}`, patch).then((resp) => {
    if (resp instanceof Response && resp.ok) {
      return resp.json();
    }
    return null;
  });

const switchProfile = async (profileId: number) => {
  const res = await ajaxActions.post(`${BASE_URL}/profiles/switchProfile/${profileId}`);
  if (res.ok) {
    BrowserS.deleteEventsFilter();
    window.location.reload();
  }
};

const getBankAccount = async (profileId: number): Promise<BankAccountDTO | null> => {
  const res = await ajaxActions.get(`${process.env.REACT_APP_AUTH_SERVICE_URL}/profiles/${profileId}/bankAccount`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const isOwnProfile = async (profileId: number): Promise<boolean> => {
  const res = await ajaxActions.get(`${BASE_URL}/profiles/${profileId}/isOwn`);
  if (res.ok) {
    const resText = await res.text();
    return resText === 'true';
  }
  return true;
};

// eslint-disable-next-line import/prefer-default-export
export const ProfileS = {
  patchProfile,
  switchProfile,
  getBankAccount,
  isOwnProfile,
};

import { MailConfigType, MailgunDomainDTO, MailOutBoundConfigDTO } from '../Types/MailT';
import { ajaxActions } from './AjaxActions';
import { ConstantS } from './ConstantS';
import { DataS } from './DataS';
import { MixpanelS } from './MixpanelS';
import { ToastS } from './ToastS';
import { isDefined } from './ValidationS';

const BASE_URL = process.env.REACT_APP_MAIL_SERVICE_URL;

const create = async (body: Partial<MailOutBoundConfigDTO>): Promise<MailOutBoundConfigDTO | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/mailOutboundConfigs`, body);
  if (res.status === 201) {
    ToastS.info('outbound-config-created', 'Mail-Konfiguration erstellt.');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const fetchMailOutboundConfig = async (id: number): Promise<MailOutBoundConfigDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/mailOutboundConfigs/${id}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchMailOutboundConfigs = async (): Promise<MailOutBoundConfigDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/mailOutboundConfigs`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const patchMailOutboundConfig = async (
  id: number,
  patch: Partial<MailOutBoundConfigDTO>,
): Promise<MailOutBoundConfigDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/mailOutboundConfigs/${id}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteMailOutboundConfig = async (id: number): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/mailOutboundConfigs/${id}`);
  if (res.ok) {
    ToastS.info('outbound-config-removed', 'E-Mail-Konfiguration gelöscht.');
    return true;
  }
  ToastS.generalError();
  return false;
};

const sendTestMail = async (id: number): Promise<boolean> => {
  const res = await ajaxActions.get(`${BASE_URL}/mailOutboundConfigs/${id}/testMail`);
  if (res.ok) {
    ToastS.success('send.mail', 'Test-E-Mail erfolgreich versendet');
    MixpanelS.track(ConstantS.TrackingEvents.MailAccountConnected);
    return true;
  }
  ToastS.error('send.mail', 'Überprüfe Deine E-Mail-Konfiguration');
  return false;
};

const resolveHint = (provider: string) =>
  DataS.mailProviderHints.filter((h) => h.provider === provider).length === 1
    ? DataS.mailProviderHints.filter((h) => h.provider === provider)[0]
    : undefined;

const resolveProviderConfig = (provider: string) => {
  const config: Partial<MailOutBoundConfigDTO> = { auth: true };

  if (provider === 'gmail') {
    config.smptHost = 'smtp.gmail.com';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'gmx') {
    config.smptHost = 'mail.gmx.net';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'strato') {
    config.smptHost = 'smtp.strato.de';
    config.smptPort = 465;
    config.startTls = false;
  } else if (provider === 'web-de') {
    config.smptHost = 'smtp.web.de';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'office365') {
    config.smptHost = 'smtp.office365.com';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'outlook' || provider === 'hotmail') {
    config.smptHost = 'smtp-mail.outlook.com';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'djbaukasten') {
    config.smptHost = 'simdesign.de';
    config.smptPort = 465;
    config.startTls = false;
  } else if (provider === 't-online') {
    config.smptHost = 'securesmtp.t-online.de';
    config.smptPort = 465;
    config.startTls = false;
  } else if (provider === '1und1') {
    config.smptHost = 'smtp.1und1.de';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'yahoo') {
    config.smptHost = 'smtp.mail.yahoo.com';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'jimdo') {
    config.smptHost = 'secure.emailsrvr.com';
    config.smptPort = 465;
    config.startTls = false;
  } else if (provider === '1blu') {
    config.smptHost = 'smtp.1blu.de';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'united-domains') {
    config.smptHost = 'smtps.udag.de';
    config.smptPort = 465;
    config.startTls = false;
  } else if (provider === 'hetzner') {
    config.smptHost = 'mail.your-server.de';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'mail-de') {
    config.smptHost = 'smtp.mail.de';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'icloud') {
    config.smptHost = 'smtp.mail.me.com';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'freenet') {
    config.smptHost = 'mx.freenet.de';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'goneo') {
    config.smptHost = 'smtp.goneo.de';
    config.smptPort = 465;
    config.startTls = false;
  } else if (provider === 'webador') {
    config.smptHost = 'mail.webador.com';
    config.smptPort = 587;
    config.startTls = true;
  } else if (provider === 'one-com') {
    config.smptHost = 'send.one.com';
    config.smptPort = 465;
    config.startTls = false;
  } else if (provider === 'ovh') {
    config.smptHost = 'ssl0.ovh.net';
    config.smptPort = 587;
    config.startTls = true;
  } else {
    return null; // Return null if the provider is not found
  }
  return config;
};

const resolveSmtpConfig = (emailAddress: string) => {
  const config: Partial<MailOutBoundConfigDTO> = { auth: true };

  if (emailAddress.includes('gmail')) {
    config.smptHost = 'smtp.gmail.com';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'gmail';
  } else if (emailAddress.includes('gmx')) {
    config.smptHost = 'mail.gmx.net';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'gmx';
  } else if (emailAddress.includes('strato')) {
    config.smptHost = 'smtp.strato.de';
    config.smptPort = 465;
    config.startTls = false;
    config.provider = 'strato';
  } else if (emailAddress.includes('web.de')) {
    config.smptHost = 'smtp.web.de';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'web-de';
  } else if (emailAddress.includes('office365')) {
    config.smptHost = 'smtp.office365.com';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'office365';
  } else if (emailAddress.includes('outlook') || emailAddress.includes('hotmail')) {
    config.smptHost = 'smtp-mail.outlook.com';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'outlook';
  } else if (emailAddress.includes('djbaukasten')) {
    config.smptHost = 'simdesign.de';
    config.smptPort = 465;
    config.startTls = false;
    config.provider = 'djbaukasten';
  } else if (emailAddress.includes('t-online')) {
    config.smptHost = 'securesmtp.t-online.de';
    config.smptPort = 465;
    config.startTls = false;
    config.provider = 't-online';
  } else if (emailAddress.includes('1und1')) {
    config.smptHost = 'smtp.1und1.de';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = '1und1';
  } else if (emailAddress.includes('yahoo')) {
    config.smptHost = 'smtp.mail.yahoo.com';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'yahoo';
  } else if (emailAddress.includes('jimdo')) {
    config.smptHost = 'secure.emailsrvr.com';
    config.smptPort = 465;
    config.startTls = false;
    config.provider = 'jimdo';
  } else if (emailAddress.includes('1blu')) {
    config.smptHost = 'smtp.1blu.de';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = '1blu';
  } else if (emailAddress.includes('united-domains')) {
    config.smptHost = 'smtps.udag.de';
    config.smptPort = 465;
    config.startTls = false;
    config.provider = 'united-domains';
  } else if (emailAddress.includes('hetzner')) {
    config.smptHost = 'mail.your-server.de';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'hetzner';
  } else if (emailAddress.includes('mail.de')) {
    config.smptHost = 'smtp.mail.de';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'mail-de';
  } else if (emailAddress.includes('icloud')) {
    config.smptHost = 'smtp.mail.me.com';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'icloud';
  } else if (emailAddress.includes('freenet')) {
    config.smptHost = 'mx.freenet.de';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'freenet';
  } else if (emailAddress.includes('goneo')) {
    config.smptHost = 'smtp.goneo.de';
    config.smptPort = 465;
    config.startTls = false;
    config.provider = 'goneo';
  } else if (emailAddress.includes('webador')) {
    config.smptHost = 'mail.webador.com';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'webador';
  } else if (emailAddress.includes('one.com')) {
    config.smptHost = 'send.one.com';
    config.smptPort = 465;
    config.startTls = false;
    config.provider = 'one.com';
  } else if (emailAddress.includes('ovh')) {
    config.smptHost = 'ssl0.ovh.net';
    config.smptPort = 587;
    config.startTls = true;
    config.provider = 'ovh';
  } else {
    config.smptHost = undefined;
    config.smptPort = undefined;
    config.startTls = true;
    config.provider = 'other';
  }
  config.login = emailAddress;
  return config;
};

const testMailAvailable = (mailConfig: MailOutBoundConfigDTO) => {
  const { externalAccountId, login, password, smptHost, smptPort } = mailConfig;
  if (externalAccountId) {
    return true;
  }
  return isDefined(login) && isDefined(password) && isDefined(smptHost) && smptPort !== null;
};

const getDomain = async (configId: number): Promise<MailgunDomainDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/mailOutboundConfigs/${configId}/domain`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const verifyDomain = async (configId: number): Promise<MailgunDomainDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/mailOutboundConfigs/${configId}/verify-domain`);
  if (res.ok) {
    const domain: MailgunDomainDTO = await res.json();
    if (domain.verified) {
      MixpanelS.track(ConstantS.TrackingEvents.MailgunDomainVerified);
      ToastS.success('domain-verified', 'Domain verifiziert.');
    }
    return domain;
  }
  return null;
};

const unsupportedProviders = ["gmail.com", "yahoo.com", "outlook.com"];

const isSmtpRelayAvailable = (emailAddress: string) => {
  const domain = emailAddress.split("@").pop()?.toLowerCase();
  return domain ? !unsupportedProviders.includes(domain) : false;
};

const resolveConfigType = (emailAddress: string): MailConfigType => {
  if (emailAddress.includes('office365') || emailAddress.includes('outlook')) {
    return 'AZURE';
  }
  return 'SMTP';
};

const isSubmittable = (config: Partial<MailOutBoundConfigDTO>, type?: MailConfigType) => {
  if (type === 'SMTP') {
    const { smptHost, smptPort, login, password } = config;
    return isDefined(smptHost) && smptPort !== undefined && isDefined(login) && isDefined(password);
  }
  return true;
};

// eslint-disable-next-line import/prefer-default-export
export const MailS = {
  create,
  fetchMailOutboundConfig,
  fetchMailOutboundConfigs,
  deleteMailOutboundConfig,
  patchMailOutboundConfig,
  sendTestMail,
  getDomain,
  verifyDomain,
  resolveHint,
  resolveProviderConfig,
  resolveSmtpConfig,
  testMailAvailable,
  isSmtpRelayAvailable,
  resolveConfigType,
  isSubmittable,
};

import React, { FC, useContext, useEffect, useState } from 'react';
import { Group, Skeleton, Text } from '@mantine/core';
import { getResourcePosition, getResourcePositionFavorite } from '../../../../Service/restapi/documentService';
import { Resource } from '../../../../Types/ResourceT';
import ResourceS from '../../../../Service/ResourceS';
import { LicenceS } from '../../../../Service/LicenceS';
import ResourceWidgetItem from '../../../Resources/aside/ResourceWidgetItem';
import { ProfileContext } from '../../../../Service/Context/ProfileContext';
import { Position } from '../../../../Service/PositionS';
import { ResourceAddonContext } from '../../../../Service/Context/ResourceAddonsContext';
import ResourceAvailabilityDot from './ResourceAvailabilityDot';
import { openResourcePositionModal } from './ResourcePositionModal';

export type ResourcePosition = {
  id: string;
  positionId: number;
  resourceId: string;
  exemplarId?: string;
  factorGroupId: string;
  planningGroupTemplateId: string;
};

type Props = {
  position: Position;
  preview: boolean;
  favoritePosition: boolean;
  event?: any;
  amount?: string;
};

const ResourcePositionAssignment: FC<Props> = ({ position, preview, favoritePosition, event, amount }) => {
  const { isActive, executeWithCheck } = useContext(ResourceAddonContext);
  const [resourcePosition, setResourcePosition] = useState<ResourcePosition | null>(null);
  const [resource, setResource] = useState<Resource | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { profile } = useContext<any>(ProfileContext);

  useEffect(() => {
    if (favoritePosition) {
      getResourcePositionFavorite(position.id)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((e) => {
              setResourcePosition(e);
            });
          } else {
            console.error(`could not load resource of position ${position.id}`);
          }
        })
        .catch(() => {
          console.error(`could not load resource of position ${position.id}`);
        })
        .finally(() => setLoading(false));
    } else {
      getResourcePosition(position.id)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((e) => {
              setResourcePosition(e);
            });
          } else {
            setResourcePosition(null);
          }
        })
        .catch(() => {
          console.error(`could not load resource of position ${position.id}`);
        })
        .finally(() => setLoading(false));
    }
  }, [position, favoritePosition]);

  useEffect(() => {
    if (resourcePosition) {
      ResourceS.fetchById(resourcePosition?.resourceId)
        .then(setResource)
        .catch(() => {
          console.error(`could not load resource ${resourcePosition?.resourceId}`);
        });
    } else if (resource) {
      setResource(null);
    }
  }, [resourcePosition]);

  if (!LicenceS.hasAccessToResourceModule(profile.id)) {
    return null;
  }

  const onClick = () => {
    openResourcePositionModal({
      resourcePosition,
      setResourcePosition,
      positionId: position.id,
      resource,
      favoritePosition,
      disableSelection: !isActive,
    });
  };

  if (loading || (resourcePosition != null && resource == null)) {
    return <Skeleton mt="sm" mb="sm" height={20} width={150} />;
  }

  return (
    <Group position="apart">
      {resource && resourcePosition ? (
        <>
          {event && amount && <ResourceAvailabilityDot eventId={event.id} resourceId={resource.id} amount={amount} />}
          <ResourceWidgetItem resource={resource} onClick={onClick} />
        </>
      ) : (
        <Text
          variant="link"
          size="xs"
          color="blue"
          sx={{ cursor: 'pointer' }}
          onClick={() => executeWithCheck(onClick)}
        >
          Ressource verknüpfen
        </Text>
      )}
    </Group>
  );
};

export default ResourcePositionAssignment;

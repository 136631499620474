// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Skeleton, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { ResourcePosition } from '../../Settings/Documents/Positions/ResourcePositionAssignment';
import { fetchFavoritePosition, fetchPosition } from '../../../Service/restapi/positionService';
import { Position } from '../../../Service/PositionS';
import { BrowserS } from '../../../Service/BrowserS';

interface Props {
  resourcePosition: ResourcePosition;
  favoritePosition: boolean;
}

const ResourcePositionItem: FC<Props> = ({ resourcePosition, favoritePosition }) => {
  const { positionId } = resourcePosition;
  const [position, setPosition] = useState<Position>();

  useEffect(() => {
    if (favoritePosition) {
      fetchFavoritePosition(positionId).then((resp) => {
        if (resp.ok) {
          resp.json().then(setPosition);
        }
      });
    } else {
      fetchPosition(positionId).then((resp) => {
        if (resp.ok) {
          resp.json().then(setPosition);
        }
      });
    }
  }, [positionId]);

  if (!position) {
    return <Skeleton mt="sm" mb="sm" height={80} />;
  }

  return (
    <Group position="apart">
      <Text className="overflow-ellipsis" sx={{ maxWidth: 150 }} size="xs" weight="bolder">
        {BrowserS.getTextContent(position.label)}
      </Text>
      {position.priceUnit !== undefined && (
        <Text size="xs" weight="lighter">{` ${position.netPriceUnit} € (${position.tax}%)`}</Text>
      )}
    </Group>
  );
};

export default ResourcePositionItem;
